<template>
  <div>
    <div id="buttonDiv">
      <img src="../assets/google.png" />
      <span>Sign in with Google</span>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { getGoogleInfo } from "@/API/index";
export default {
  props: ["closeLoginForm"],
  data() {
    return {
      access_token: "",
      // GOOGLE_CLIENT_ID:
      //   "592187663113-553jkmrhvm3a49v40lbi5b029sac70t5.apps.googleusercontent.com",
      form: {},
    };
  },
  mounted() {
    this.open();
  },
  methods: {
    async open() {
      console.log("1111111")
      const google_init = (fn) => {
        google.accounts.id.initialize({
          client_id:
            "592187663113-553jkmrhvm3a49v40lbi5b029sac70t5.apps.googleusercontent.com", //客户端ID(创建应用第三步中获得的id)
          callback: fn,
        });
        google.accounts.id.renderButton(
          document.getElementById("buttonDiv"), //自定义按钮
          {}
        );
      };
      const google_load = (response) => {
        // //console.log(response);
        const responsePayload = jwt_decode(response.credential);
        // //console.log(responsePayload);
        // this.$emit("googleID", responsePayload.sub);
        this.form.sub = responsePayload.sub;
        this.form.email = responsePayload.email;
        this.form.name = responsePayload.name;
        this.form.picture = responsePayload.picture;
        getGoogleInfo(this.form).then((res) => {
          // //console.log(res.data.data);
          if (res.data.data.type == 0) {
            this.$router.push("/binding");
            this.$store.commit("STORAGE_TOKEN", res.data.data.token);
            this.$store.commit("STORAGE_USER_INFO", res.data.data);
            this.closeLoginForm();
          } else {
            this.$emit("googleLogin", res.data.data);
          }
        });
      };

      setTimeout(() => {
        google_init(google_load);
      }, 1000);
    },
  },
  // watch: {
  //   access_token(newVal, oldVal) {
  //     if (newVal != "" && oldVal == "") {
  //       //console.log(newVal);
  //       getGoogle({ access_token: newVal }).then((res) => {
  //         // //console.log(res.data.data.sub);
  //         this.$emit("googleID", res.data.data.sub);
  //       });
  //     }
  //   },
  // },
};
</script>

<style lang="less" scoped>
#buttonDiv {
  height: 50px;
  display: flex;
  align-items: center;
  img {
    height: 100%;
    transform: translateX(-3px);
  }
}
#buttonDiv2 {
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    height: 100%;
    transform: translateX(-3px);
  }
}
</style>