<template>
  <div>
    <div
      v-if="isMobile"
      class="container"
      :style="{
        width: $i18n.locale == 'zh' ? '90%' : '100%',
      }"
    >
      <div class="logo" @click="toIndex">
        <img :src="info.web_logo" />
        <!-- <span>{{ info.site_title }}</span> -->
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        router
        background-color="#32485c"
        text-color="#fff"
        active-text-color="#d0ffd4"
        :style="{
          marginRight: '10px',
          fontFamily: $i18n.locale == 'zh' ? '黑体' : '',
        }"
        @select="getRoute"
      >
        <el-menu-item
          index="/index"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
          >{{ $t("nav.home") }}</el-menu-item
        >
        <el-menu-item
          index="/about"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
          >{{ $t("nav.about_us") }}</el-menu-item
        >
        <el-menu-item
          index="/filter/school"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.international_schools") }}
        </el-menu-item>
        <el-menu-item
          index="/filter/train"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.tutoring_courses") }}
        </el-menu-item>
        <el-menu-item
          index="/filter/house"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.school_district_housing") }}
        </el-menu-item>
        <el-menu-item
          index="/activity"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.event_information") }}
        </el-menu-item>
        <el-menu-item
          index="/relation"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.contact_Us") }}
        </el-menu-item>
        <el-menu-item
          v-if="this.$store.state.sdawdascafawdfaw != ''"
          index="/mine"
          :style="{
            padding: $i18n.locale == 'zh' ? '0 20px' : '0 15px',
            fontSize: $i18n.locale == 'zh' ? '18px' : '16px',
          }"
        >
          {{ $t("nav.personal_center") }}
        </el-menu-item>
      </el-menu>
      <div class="login">
        <el-dropdown
          v-if="this.$store.state.userInfo.token"
          @command="handleCommand"
          placement="bottom-start"
          style="width: 50px; height: 50px"
        >
          <img
            :src="
              this.$store.state.userInfo.headimg
                ? this.$store.state.userInfo.headimg
                : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
            "
            style="width: 50px; height: 50px"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changeInfo">
              {{ $t("nav.account_settings") }}
            </el-dropdown-item>
            <el-dropdown-item command="logOut">
              {{ $t("nav.log_out") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-else class="login_visible">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="login"
            :style="{ width: $i18n.locale == 'zh' ? '56px' : '90px' }"
          >
            {{ $t("nav.login") }}
          </el-button>
          <el-button
            type="success"
            plain
            size="mini"
            @click="register"
            :style="{ width: $i18n.locale == 'zh' ? '56px' : '90px' }"
          >
            {{ $t("nav.Register") }}
          </el-button>
        </div>
        <el-dropdown
          trigger="click"
          placement="bottom"
          size="medium"
          @command="changeLang"
        >
          <div class="lang">
            <img src="../assets/lang.png" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in lang" :key="item.id" :command="item.id">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div class="lang">
        <el-dropdown>
          <span class="el-dropdown-link">
            选择语言<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>中文</el-dropdown-item>
            <el-dropdown-item>英文</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
    </div>
    <div class="mobile" v-else>
      <div class="menu">
        <i class="el-icon-menu" @click="openMenu"></i>
        <div class="logo" @click="toIndex">
          <img src="../assets/apple-logo.png" />
        </div>
      </div>

      <div class="login">
        <el-dropdown
          v-if="this.$store.state.userInfo.token"
          @command="handleCommand"
          placement="bottom-start"
          style="width: 50px; height: 50px"
        >
          <img
            :src="
              this.$store.state.userInfo.headimg
                ? this.$store.state.userInfo.headimg
                : '../assets/default.png'
            "
            style="width: 50px; height: 50px"
          />
          <div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="changeInfo">
                {{ $t("nav.account_settings") }}
              </el-dropdown-item>
              <el-dropdown-item command="logOut">
                {{ $t("nav.log_out") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
        <i v-else @click="login" class="el-icon-user-solid"></i>
      </div>
      <el-dropdown trigger="click" placement="bottom" size="medium" @command="changeLang">
        <div class="lang">
          <img src="../assets/lang.png" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in lang" :key="item.id" :command="item.id">
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
      :visible.sync="drawer"
      direction="ltr"
      :size="windowWitch > 768 ? '25%' : '75%'"
      custom-class="mobile_nav"
    >
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="vertical"
          router
          background-color="#32485c"
          text-color="#fff"
          active-text-color="#d0ffd4"
          @select="getRoute"
        >
          <el-menu-item index="/index">{{ $t("nav.home") }}</el-menu-item>
          <el-menu-item index="/about">{{ $t("nav.about_us") }}</el-menu-item>
          <el-menu-item index="/filter/school">
            {{ $t("nav.international_schools") }}
          </el-menu-item>
          <el-menu-item index="/filter/train">
            {{ $t("nav.tutoring_courses") }}
          </el-menu-item>
          <el-menu-item index="/filter/house">
            {{ $t("nav.school_district_housing") }}
          </el-menu-item>
          <el-menu-item index="/activity">
            {{ $t("nav.event_information") }}
          </el-menu-item>
          <el-menu-item index="/relation">
            {{ $t("nav.contact_Us") }}
          </el-menu-item>
          <el-menu-item v-if="this.$store.state.sdawdascafawdfaw != ''" index="/mine">{{
            $t("nav.personal_center")
          }}</el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getLogo } from "@/API/index";
export default {
  data() {
    return {
      activeIndex: "",
      windowWitch: "",
      isMobile: true,
      drawer: false,
      info: {},
      lang: [
        { id: "zh", name: "简体中文" },
        { id: "en", name: "English" },
        { id: "thai", name: "ไทย" },
      ],
    };
  },
  mounted() {
    // // //console.log(this.$store.state.visibleLogin);
    this.refresh();
    this.getLogoInfo();
    this.windowWitch = document.documentElement.clientWidth;
    this.isMobile = document.documentElement.clientWidth > 1500;
    window.addEventListener("resize", () => {
      this.isMobile = document.documentElement.clientWidth > 1500;
    });
  },
  methods: {
    toIndex() {
      this.$router.push("/index");
    },
    getLogoInfo() {
      getLogo({ lang: this.$store.state.lang }).then((res) => {
        // //console.log(res.data.data);
        this.info = res.data.data;
        this.$store.commit("GET_INFO", this.info);
      });
    },
    login() {
      setTimeout(() => {
        this.$store.commit("CHANGE_VISIBLE_LOGIN", true);
      }, 100);
    },
    register() {
      setTimeout(() => {
        this.$store.commit("CHANGE_VISIBLE_REGISTER", true);
      }, 100);
    },
    getRoute(index) {
      this.activeIndex = index;
      // //console.log(this.activeIndex);
      this.$store.commit("CHANGE_VISIBLE_NAV", index);
    },
    refresh() {
      // //console.log(this.$route.path);
      this.activeIndex = this.$route.path;
    },
    openMenu() {
      this.drawer = true;
    },
    handleCommand(command) {
      if (command == "changeInfo") {
        this.$router.push("/mine/account");
      } else if (command == "logOut") {
        if (document.documentElement.clientWidth > 768) {
          this.$confirm(this.$t("nav.is_logout"), this.$t("nav.reminder"), {
            confirmButtonText: this.$t("nav.confirm"),
            cancelButtonText: this.$t("nav.cancel"),
            type: "warning",
          })
            .then(() => {
              this.$store.commit("STORAGE_USER_INFO", {});
              this.$store.commit("STORAGE_TOKEN", "");
              // facebook登出
              // FB.logout(function (response){
              //   //console.log(response);
              // });
              this.$message({
                type: "success",
                message: this.$t("nav.logged_out") + "!",
              });
            })
            .catch(() => {
              this.$message({
                message: this.$t("nav.cancelled"),
                type: "warning",
              });
            });
        } else {
          this.$store.commit("STORAGE_USER_INFO", {});
          this.$store.commit("STORAGE_TOKEN", "");
          // facebook登出;
          FB.logout(function (response) {
            //console.log(response);
          });
          this.$message({
            type: "success",
            message: this.$t("nav.logged_out") + "!",
          });
        }
      }
    },
    changeLang(lang) {
      // console.log(lang, this.$i18n.locale);
      this.$i18n.locale = lang;
      this.$store.commit("CHANGE_LANG", lang);
      location.reload();
    },
  },
  watch: {
    $route(to, from) {
      this.drawer = false;
      this.activeIndex = to.path;
      this.$store.commit("CHANGE_VISIBLE_NAV", to.path);
      // if (to.path == "/mine" && this.$store.state.userInfo.type == 0) {
      //   this.$router.push("/index");
      //   localStorage.setItem("isVisible", JSON.stringify(true));
      //   this.$message.warning("请选择身份");
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-menu__item {
  text-align: center;
}
.container {
  width: 90%;
  min-width: 1031px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    // margin-left: 50px;
    cursor: pointer;
    img {
      height: 92%;
      margin-right: 10px;
    }
    span {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      font-family: "黑体";
      cursor: default;
    }
  }
  .el-menu-demo {
    display: flex;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu-item {
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
  }
  .is-active {
    border-bottom: transparent !important;
  }
  .login {
    text-align: center;
    color: #fff;
    // height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
    }
    .login_visible {
      // width: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 56px;
        height: 28px;
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }
  .lang {
    margin-left: 20px;
    height: 25px;
    img {
      height: 100%;
    }
  }
}
.mobile {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 24px;
    color: #fff;
  }
  .menu {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 2%;
    .logo {
      height: 88%;
      margin-left: 10px;
      img {
        height: 100%;
      }
    }
  }
  .login {
    margin-left: auto;
    margin-right: 10px;
  }
  /deep/.el-dropdown {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.el-drawer__wrapper {
  z-index: 1000009 !important;
}
/deep/.el-menu {
  border: none;
}
.el-dropdown-menu {
  top: 55px !important;
}
/deep/.el-menu-item:hover {
  // background-color: rgb(0, 168, 235) !important;
  background-color: #4d6e8d !important;
}
/deep/ .el-drawer {
  background: #32485c !important;
  .el-dialog__close {
    color: #fff;
  }
}
@media screen and (max-width: 1500px) {
  .mobile {
    padding-right: 2%;
  }
  .login {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    margin-left: auto;
    margin-right: 0 !important;
    i {
      line-height: 50px;
    }
  }
  .lang {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-left: 10px;
    img {
      height: 100%;
    }
  }
}
</style>
