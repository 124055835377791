export default {
    //导航
    nav: {
        home: "Home",
        about_us: "About Us",
        international_schools: "International Schools",
        tutoring_courses: "Tutoring Courses",
        school_district_housing: "School District Housing",
        event_information: "Event Information",
        contact_Us: "Contact Us",
        personal_center: "Personal Center",
        account_settings: "Account Settings",
        log_out: "Log out",
        login: "Login",
        Register: "Register",
        is_logout: "Are you sure you want to log out?",
        reminder: "Reminder",
        confirm: "Confirm",
        cancel: "Cancel",
        cancelled: "Log out cancelled",
        logged_out: "Logged out"
    },
    // 登录
    login: {
        account_login: "Password login",
        mobile_login: "Mobile login",
        account_placeholder: "Please enter mobile or email",
        password_placeholder: "Please enter password",
        login: "Login",
        choose_placeholder: "Please select",
        mobile_placeholder: "Please enter mobile",
        code_placeholder: "Please enter verification code",
        forget_pwd: "Forgot password",
        register_now: "Register now",
        agree: "Continuing indicates that you agree to",
        terms_of_use: "Terms and Conditions",
        read: "Please read our ",
        privacy_policy: "Privacy Policy",
        other_login: "Login via third-party account",
        register_title: "Registration identity",
        email_register: "Email registration",
        mobile_register: "Mobile registration",
        mobile: "Mobile",
        email: "Email",
        email_placeholder: "Please enter your email",
        code: "Verification Code",
        type: "User type",
        type_1: "Tutoring Institution",
        type_2: "Real Estate Agency",
        type_3: "Parents",
        type_4: "Teacher",
        type_1_name: "Institution name",
        type_2_name: "Agency name",
        type_3_name: "Nickname",
        type_4_name: "Full name",
        type_1_name_placeholder: "Please enter institution name",
        type_2_name_placeholder: "Please enter agency name",
        type_3_name_placeholder: "Please enter nickname",
        type_4_name_placeholder: "Please enter full name",
        area: "Location",
        country_chose: "Please select country",
        area_placeholder: "Please set the location",
        password: "Password",
        password_set: "Please set password",
        skip: "Skip for now",
        getCode: "Get verification code",
        time_1: "Retry after ",
        time_2: " seconds",
        mobile_format_1: "Please check the format of the entered mobile number",
        account_format: "Account cannot be empty",
        password_format: "Password cannot be empty",
        mobile_format_2: "Mobile number cannot be empty",
        mobile_format_3: "Please enter correct mobile number format",
        code_format: "Verification code cannot be empty",
        is_login: "Login successful",
        verify: "Verification information"
    },
    index: {
        more: "More",
        international_schools: "International Schools",
        tutoring_courses: "Tutoring Courses",
        school_district_housing: "School District Housing",
        event_information: "Event Information",
        back_btn: "Back to Top",
        nav_1: "Schools",
        nav_2: "Tutoring",
        nav_3: "Housing",
        nav_4: "Events",
        nav_5: "Back to Top"
    },
    about_us: {
        about_us: "About Us",
        terms_of_use: "Terms and Conditions",
        privacy_policy: "Privacy Policy"
    },
    filter: {
        set_city_school: "View Schools by City",
        set_course_school: "View Schools by Curriculum",
        set_sort_course: "View Courses by Type",
        all_course: "All Courses",
        set_sort_house: "View Properties by Type",
        all_sort: "All Types",
        set_school_house: "View Properties by Schools",
        chose_school: "Please select or enter a school",
        search: "Click to search",
    },
    school: {
        school_key: "Keywords",
        set_school_key: "Please enter school name",
        area: "Location",
        course: "Curriculum",
        set_course: "Please select a curriculum",
        other_course: "Curriculum Details",
        set_other_course: "Please select or enter curriculum",
        level: "Education Level",
        set_level: "Please select education level",
        lang: "Language of Instruction",
        set_lang: "Please select language of instruction",
        lodge: "Boarding",
        lodge_1: "Boarding",
        lodge_2: "Non-boarding",
        money: "Tuition Fee Range (Unit：THB)",
        start_money: "Min Tuition Fee",
        end_money: "Max Tuition Fee",
        search: "Search",
        other_school: "More Schools",
        school_list: "International School List",
        find_1: "Found a total of ",
        find_2: " international school",
        no_more: "No more schools",
        back: "Back",
        school_detail: "School Information",
        bookmark: "Bookmark",
        bookmarked: "Bookmarked",
        copy: "Copy URL and share",
        message: "Important Information",
        age: "Enrollment Age Range",
        money_detail: "Tuition",
        lodge_detail: "Boarding",
        time: "Establishment Date",
        about: "About ",
        location: "School Location",
        comment: "Comments",
        set_comment: "Post a friendly comment",
        submit: "Post",
        no_more_commit: "No more comments",
        submit_success: "Posted",
        copy_success: "URL copied",
        copy_warning: "Copy failed"
    },
    train: {
        course_name: "Course Name",
        set_course_name: "Please enter course name",
        area: "Location",
        sort: "Course Type",
        set_sort: "Please select course type",
        type: "Institution Type",
        set_type: "Please select institution or private tutor",
        lang: "Language of Instruction",
        set_lang: "Please select language of instruction",
        is_online: "Method of Delivery",
        set_is_online: "Please select online or in-person",
        class: "Class Size",
        set_class: "Please select a type",
        pay: "Payment Method",
        set_pay: "Please select payment method",
        age: "Age Range",
        start_age: "Minimum Age",
        end_age: "Maximum Age",
        search: "Search",
        more: "More courses",
        list: "List of Tutoring Courses",
        find_1: "Total found ",
        find_2: " tutoring course",
        no_more: "No more courses",
        course_info: "Course Information",
        train_info: "Institution Information",
        train_name: "Institution Name",
        train_location: "Institution Location",
        train_email: "Contact",
        train_www: "Institution Website",
        teacher_info: "Teacher Details",
        teacher_name: "Teacher Name",
        teacher_detail: "Teacher Profile",
        teacher_type: "Teacher Type",
        teacher_lang_1: "Native Speaker",
        teacher_lang_2: "Non-native Speaker",
        teacher_certificate: "Qualifications",
        teacher_contact: "Contact",
        teacher_email: "Email",
    },
    house: {
        location: "Location",
        type: "Property Type",
        set_type: "Please select property type",
        school: "Schools nearby",
        set_school: "Please select schools nearby",
        money: "Rental Range (Unit: THB)",
        min_money: "Minimum Rent",
        max_money: "Maximum Rent",
        area: "Property Area ",
        min_area: "Minimum Area",
        max_area: "Maximum Area",
        jiaju: "Furnishing",
        set_jiaju: "Please select furnishing",
        time: "Release Time",
        search: "Search",
        more: "More Information",
        list: "School District Housing List",
        find_1: "Found ",
        find_2: " school district housing",
        no_more: "No more properties",
        mouth: "month",
        detail: "Property Information",
        jiaju: "Furnishing",
        type: "Type",
        room: "Number of Bedrooms",
    },
    active: {
        label: "Overview",
        detail: "Introduction",
        picture_wall: "Photo Wall",
        address: "Address",
        get_address: "Get Location",
        type: "Type",
        time: "Date",
        start: "Start",
        end: "End",
        copy_success: "Address Copied",
        copy_warning: "Failed to Copy Address"
    },
    contact: {
        message: "Please leave us a message, and we will get back to you soon.",
        name_1: "Last name",
        name_2: "First name",
        chose: "Please select",
        mobile: "Please enter mobile number",
        email: "Email",
        leave_message: "Please leave your message here.",
        submit: "Submit",
    },
    layout: {
        index: "Home",
        account: "Account Settings",
        message: "Post Information",
        comment: "Saved Comments",
    },
    account: {
        title: "Account Settings",
        change: "Change logo or avatar",
        fail: "Failed to load image",
        img_title: "Click to change user avatar",
        change_info: "Edit personal information",
        train_name: "Institution name",
        person_name: "Agency name",
        nickname: "Nickname",
        teacher_name: "Teacher's name",
        member: "Mobile number",
        change_mobile_1: "Change mobile",
        change_mobile_2: "Bind Mobile",
        email: "Email",
        change_email_1: "Change email",
        change_email_2: "Bind Email",
        name: "Name",
        set_name: "Please enter your name",
        area: "Location",
        user: "User type",
        user_type: "Regular user",
        password: "Set password",
        save: "Save",
        change_password: "Change personal password",
        old_pwd: "Old password",
        new_pwd: "New password",
        change_mobile: "Edit mobile number",
        code: "Get verification code",
        finish: "Done",
        placeholder: "Please select",
        new_mobile: "Please enter mobile number",
        set_code: "Please enter verification code",
        button_1: "Cancel",
        button_2: "Confirm",
        change_email: "Change Email",
        new_email: "Please enter your email",
        type_name_1: "Tutoring Institution",
        type_name_2: "Real Estate Agency",
        type_name_3: "Parents",
        type_name_4: "Teacher",
        message: "Personal information has not been changed.",
        up_img: "Uploaded image should be in JPG or PNG format",
        img_big: "Uploaded image size should not exceed 2MB",
        password_title_1: "Current password cannot be empty.",
        password_title_2: "New password cannot be empty.",
        password_title_3: "New password cannot be the same as the current password.",
        time_1: "Retry after ",
        time_2: " seconds",
        mobile_type: "Please check the format of the entered mobile number"
    },
    message: {
        title: "Post Information",
        house: {
            label: "Property Information",
            id: "Listing ID",
            type: "Type of property",
            set_type: "Please select the type of property",
            title: "Title",
            set_title: "Please enter a title",
            area: "Location",
            set_area: "Please enter the location",
            address: "Address",
            set_address: "Please enter the address",
            school: "Schools nearby",
            set_school: "Please select or enter a school",
            money: "Rent (Thai Baht)",
            set_money: "Please enter the rent",
            size: "Property area",
            set_size: "Please enter the area of the property",
            jiaju: "Furnishing",
            set_jiaju: "Please select furnishing",
            room: "Number of bedrooms",
            set_room: "Select number of bedrooms",
            detail: "Property description",
            img: "Property images",
            up_img: "Uploaded images",
        },
        course: {
            label: "Course Information",
            name: "Course name",
            set_name: "Please enter course name",
            id: "Course ID",
            logo: "Course logo",
            area: "Location",
            set_area: "Please enter the location",
            type: "Course type",
            set_type: "Please select course type",
            train_type: "Institution type",
            set_train_type: "Please select institution or private tutor",
            online: "Method of delivery",
            set_online: "Please select online or in-person",
            lang: "Language of instruction",
            set_lang: "Please select language of instruction",
            class_type: "Class size",
            set_class_type: "Please select a type",
            pay: "Payment method",
            set_pay: "Please select payment method",
            age: "Age range",
            min_age: "Minimum age",
            max_age: "Maximum age",
            info: "Course information",
            img: "Course images",
            up_img: "Uploaded images"
        },
        train: {
            label: "Institution Information",
            name: "Institution name",
            set_name: "Please enter the institution name",
            area: "Location",
            set_area: "Please select country",
            set_address: "Please enter the location of the institution",
            contact: "Contact",
            set_contact: "Please enter the institution email",
            address: "Address",
            address_1: "Please enter the institution address",
            web: "Website",
            set_web: "Please enter the institution website URL",
            btn: "Submit"
        },
        teacher: {
            label: "Teacher Information",
            name: "Name",
            set_name: "Please enter the teacher name",
            detail: "Introduction",
            set_detail: "Please enter the teacher introduction",
            lang: "Native speaker",
            certificate: "Qualification Certificate",
            set_certificate: "Please enter relevant qualification certificate",
            contact: "Contact",
            email: "Email",
            set_email: "Please enter your email",
            other: "Other contact",
            set_other: "Please enter your contact",
            btn: "Submit"
        },
        rules: {
            rule_1: "Please select property type",
            rule_2: "Please enter a title",
            rule_3: "Please select country",
            rule_4: "Please enter the address",
            rule_5: "Please enter the rent",
            rule_6: "Please enter the area of the property",
            rule_7: "Please select furnishing",
            rule_8: "Select number of bedrooms",
            rule_9: "Please fill in the property information.",
            rule_10: "Please enter course name",
            rule_11: "Please enter course Logo",
            rule_12: "Please select institution or private tutor",
            rule_13: "Please select online or in-person",
            rule_14: "Please select language of instruction",
            rule_15: "Please select course type",
            rule_16: "Please select a type",
            rule_17: "Please select payment method",
            rule_18: "There should be at least one minimum age",
            rule_19: "Please enter course information",
            rule_20: "Please complete institution information first",
            rule_21: "Please complete teacher information first",
        }
    },
    personal: {
        collect: "Saved Comments",
        my_submit: "My Posts",
        my_comment: "My Comments",
        my_collect: "My Bookmarks",
        new_submit: "Latest Posts",
        course: "Course Information",
        time: "Post Time",
        detail: "View Details",
        modify: "Edit Content",
        del: "Delete this Post",
        no_course_message: "No course information posted yet",
        house: "Property Information",
        no_house_message: "No property information posted yet",
        mew_comment: "Latest Comments",
        school_comment: "School Reviews",
        course_comment: "Course Reviews",
        no_comment: "No more comments posted yet",
        school_title: "International Schools",
        course_title: "Tutoring Courses",
        house_title: "School district housing",
        no_collect: "No more bookmarks",
        del_title: "This action will permanently delete this post",
        is_del: "Are you sure you want to continue?",
        title: "Note",
        yes: "Confirm",
        no: "Cancel",
        del_success: "Deleted!",
        del_warn: "Deletion Cancelled"
    },
    password: {
        account: "Account Settings",
        back: "Return to Homepage",
        password: "Retrieve Password",
        way: "Please Select Retrieval Method",
        mobile: "Mobile Number",
        email: "Email",
        chose: "Please select",
        inner_email: "Please Enter Verification Code",
        inner_mobile: "Please Enter Your Mobile Number",
        inner_code: "Please enter verification code",
        new_pwd: "Please Enter New Password",
        pwd_input: "Confirm Password",
        last_step: "Previous Step",
        next_step: "Next Step",
        get_code: "Get Verification Code",
        finish: "Complete",
        rules: {
            rule_1: "Input cannot be empty",
            rule_2: "Passwords entered must match",
            rule_3: "Password changed successfully"
        }
    },
    status: {
        choose: "Select Identity",
        choose_title: "Please Select Your Identity",
        choose_title_2: "Select Your Identity for the First Time Login",
        chose_btn: "Please Select Your Identity",
        status_1: "Tutoring Institution",
        status_2: "Real Estate Agency",
        status_3: "Parents",
        status_4: "Teacher",
        finish: "Complete"
    },
    footer: {
        index: "Home",
        course: "Tutoring Courses",
        active: "Event Information",
        contact: "Contact Us",
        school: "International Schools",
        school_1: "International School in Bangkok",
        school_2: "International School in Phuket",
        school_3: "International School in Chiang Mai",
        house: "School District Housing",
        house_1: "Condominium",
        house_2: "Townhouse",
        house_3: "Detached House",
        weChat: "WeChat",
        // text: "备案号",
        text_1: "Terms of Use",
        text_2: "Privacy Policy"
    }
}