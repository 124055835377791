<template>
  <div class="copyright">
    <div class="content">
      <div class="copyright_text">
        <div class="copyright_link">
          <div class="copyright_link_item">
            <div class="copyright_link_item_header" @click="goIndex('/index')">
              {{ $t("footer.index") }}
            </div>
            <ul class="copyright_link_item_info">
              <li @click="goIndex('/searchorgan')">
                {{ $t("footer.course") }}
              </li>
              <li @click="goIndex('/activity')">{{ $t("footer.active") }}</li>
              <li @click="goIndex('/relation')">{{ $t("footer.contact") }}</li>
            </ul>
          </div>
          <div class="copyright_link_item">
            <div
              class="copyright_link_item_header"
              @click="goIndex('/filter/school')"
            >
              {{ $t("footer.school") }}
            </div>
            <ul class="copyright_link_item_info">
              <li @click="goSchool(8, 1)">{{ $t("footer.school_1") }}</li>
              <li @click="goSchool(10, 1)">{{ $t("footer.school_2") }}</li>
              <li @click="goSchool(12, 1)">{{ $t("footer.school_3") }}</li>
            </ul>
          </div>
          <div class="copyright_link_item">
            <div
              class="copyright_link_item_header"
              @click="goIndex('/filter/house')"
            >
              {{ $t("footer.house") }}
            </div>
            <ul class="copyright_link_item_info">
              <li @click="goHouse(1)">{{ $t("footer.house_1") }}</li>
              <li @click="goHouse(2)">{{ $t("footer.house_2") }}</li>
              <li @click="goHouse(3)">{{ $t("footer.house_3") }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright_img">
        <div class="copyright_logo">
          <img :src="$store.state.info.web_logo" />
        </div>
        <div class="copyright_code">
          <div class="copyright_code_item">
            <span>{{ $t("footer.weChat") }}: {{ info.wechat }}</span
            ><img :src="info.wxewm" alt="" />
          </div>
          <div class="copyright_code_item">
            <span>Line: {{ info.line }}</span
            ><img :src="info.lineewm" alt="" />
          </div>
        </div>
      </div>
      <div class="route">
        <a :href="info.wechat_url"> </a>
        <a :href="info.facebook_url"> </a>
        <a :href="info.linkedin_url"> </a>
      </div>
    </div>
    <div class="copyright_footer">
      <p>
        Copyright ©2023 Walson International Education. All Rights Reserved.
      </p>
      <div>
        <router-link target="_blank" to="/privacy/3">{{
          $t("footer.text_1")
        }}</router-link>
        <span> | </span>
        <router-link target="_blank" to="/privacy/1">{{
          $t("footer.text_2")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.info = this.$store.state.info;
      // console.log(this.info);
    },
    goIndex(url) {
      if (this.$route.path != url) {
        this.$router.push(url);
      } else {
        // this.$message({
        //   message: "你已经在这个页面了哦",
        //   type: "warning",
        // });
        this.$router.push(url);
      }
      document.documentElement.scrollTop = 0;
    },
    goSchool(value1, value2) {
      if (this.$route.path != "/school") {
        this.$store.commit("SUBMIT_SEARCH_INFO", {
          city: [value2, value1],
        });
        this.$router.push("/school");
      } else {
        // this.$message({
        //   message: "你已经在这个页面了哦",
        //   type: "warning",
        // });
        this.$store.commit("SUBMIT_SEARCH_INFO", {
          city: [value2, value1],
        });
        // this.$router.push("/school");
        this.$router.go(0);
      }
      document.documentElement.scrollTop = 0;
    },
    goHouse(value) {
      if (this.$route.path != "/housedetail") {
        this.$store.commit("SUBMIT_SEARCH_INFO", {
          type: value,
        });
        this.$router.push("/housedetail");
      } else {
        // this.$message({
        //   message: "你已经在这个页面了哦",
        //   type: "warning",
        // });
        this.$store.commit("SUBMIT_SEARCH_INFO", {
          type: value,
        });
        this.$router.go(0);
      }
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style scoped lang='less'>
@media screen and (min-width: 750px) {
}
.copyright {
  width: 100%;
  padding: 30px 0;
  background: #32485c;
  color: #fff;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    padding: 0 0 20px;
    position: relative;
    .copyright_img {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .copyright_logo {
        // width: 40%;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .copyright_code {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        .copyright_code_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
        }
        img {
          width: 100px;
          height: 100px;
          margin-top: 5px;
        }
      }
    }
    .copyright_text {
      flex: 1;
      margin-right: 60px;
      font-size: 14px;
      .copyright_link {
        display: flex;
        justify-content: space-evenly;
        .copyright_link_item_header {
          font-size: 16px;
          cursor: pointer;
          border-bottom: 1px solid transparent;
          text-align: center;
        }
        .copyright_link_item_header:hover {
          border-bottom: 1px solid #fff;
        }
        .copyright_link_item_info {
          text-align: center;
          margin-top: 30px;
          li {
            font-size: 14px;
            margin-top: 10px;
            color: #ccc;
            cursor: default;
            border-bottom: 1px solid transparent;
          }
          li:hover {
            cursor: pointer;
            color: #fff;
            border-bottom: 1px solid #fff;
          }
        }
      }
      /deep/.el-divider--vertical {
        background: #fff;
        margin: 0 10px;
      }
    }
    .route {
      position: absolute;
      left: 10%;
      bottom: 20px;
      display: flex;
      a {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
      }
      a:nth-child(1) {
        background: url(../assets/index/wechat.png) no-repeat center;
        background-size: 100%;
      }
      a:nth-child(2) {
        background: url(../assets/index/facebook.png) no-repeat center;
        background-size: 100%;
      }
      a:nth-child(3) {
        background: url(../assets/index/linkedin.png) no-repeat center;
        background-size: 100%;
      }
      a:nth-child(1):hover {
        background: url(../assets/index/wechat_1.png) no-repeat center;
        background-size: 100%;
      }
      a:nth-child(2):hover {
        background: url(../assets/index/facebook_1.png) no-repeat center;
        background-size: 100%;
      }
      a:nth-child(3):hover {
        background: url(../assets/index/linkedin_1.png) no-repeat center;
        background-size: 100%;
      }
      a:hover {
        border: none;
      }
    }
  }
  .copyright_footer {
    width: 80%;
    margin: auto;
    padding: 20px 0 0;
    border-top: 1px solid #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: right;
    p {
      flex: 1;
    }
  }
}
a {
  color: #fff;
}
a:hover {
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 750px) {
  .copyright {
    .content {
      flex-direction: column;
      width: 100%;
      padding: 20px;
      font-size: 12px;
      .copyright_text {
        width: 100%;
        margin-right: 0;
        margin-top: 30px;
        .copyright_link {
          margin-bottom: 20px;
        }
      }
      .route {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 10px;
          margin-right: 0;
        }
        q a:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>