<template>
  <div class="banner">
    <el-carousel
      :height="bannerHeight + 'px'"
      style="border-radius: 0 0 10px 10px"
    >
      <el-carousel-item v-for="item in imgList" :key="item.id">
        <img :src="item.banner" style="width: 100%;" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getBanner } from "@/API/index";
export default {
  props: ["type"],
  data() {
    return {
      imgList: [],
      bannerHeight: 815,
    };
  },
  mounted() {
    this.bannerHeight = (document.documentElement.clientWidth / 40) * 9;
    window.addEventListener("resize", this.windowWidthChange);
    this.getBannerList();
  },
  methods: {
    windowWidthChange() {
      this.bannerHeight = (document.documentElement.clientWidth / 40) * 9;
    },
    async getBannerList() {
      await getBanner({ type: this.type }).then((res) => {
        this.imgList = res.data.data.banner;
      });
    },
  },
  watch:{
    type(newVal,oldVal){
      this.getBannerList();
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
}
</style>