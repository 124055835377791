<template>
  <div class="slide">
    <slide-verify
      ref="slideblock"
      :l="42"
      :r="10"
      :w="310"
      :h="155"
      :slider-text="text"
      @success="onSuccess"
      @fail="onFail"
      @refresh="onRefresh"
    ></slide-verify>
    <div>{{ msg }}</div>
  </div>
</template>

<script>
export default {
  props: ["openBtn"],
  data() {
    return {
      msg: "",
      text: this.$t("login.verify"),
    };
  },
  methods: {
    onSuccess() {
      setTimeout(() => {
        this.$emit("slideSuccess", this.openBtn);
        this.$refs.slideblock.reset();
      }, 1000);
    },
    onFail() {
      this.msg = "";
    },
    onRefresh() {},
  },
};
</script>

<style lang="less" scoped>
.slide {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 9999;
  background: #fff;
  padding: 20px;
  transform: translate(-50%, 100%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
</style>