var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Nav"},[_c('div',{staticClass:"lun",staticStyle:{"width":"100%","margin":"0"}},[_c('el-carousel',{staticStyle:{"border-radius":"0 0 10px 10px"},attrs:{"height":_vm.bannerHeight + 'px'}},_vm._l((_vm.imgList),function(item){return _c('el-carousel-item',{key:item.id + 'banner'},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.banner}})])}),1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"des-title",attrs:{"id":"des"}},[_c('div',[_vm._v(_vm._s(_vm.$t("index.international_schools")))]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/school')}}},[_vm._v(" "+_vm._s(_vm.$t("index.more"))+" ")])]),_c('div',{ref:"schoolList",staticClass:"des school-list"},_vm._l((_vm.schoolList),function(item,index){return _c('div',{key:item.id + 'school',ref:"school",refInFor:true,staticClass:"school-item",style:([
          {
            height:
              index == 0 ? _vm.itemHeight * 2 + 10 + 'px' : _vm.itemHeight + 'px',
          },
        ]),on:{"click":function($event){return _vm.openDetail('school', item)},"contextmenu":function($event){return _vm.openDetail('school', item)}}},[_c('a',{staticStyle:{"width":"100%","height":"100%"},attrs:{"href":"https://www.walson.net#/school"}},[_c('img',{ref:"schoolImgs",refInFor:true,staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../assets/loading.gif"),"data-url":item.show_image}})])])}),0),_c('div',{staticClass:"train2",attrs:{"id":"train2"}},[_c('div',{staticClass:"train-title2"},[_c('span',[_vm._v(_vm._s(_vm.$t("index.tutoring_courses")))]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.to_search}},[_vm._v(" "+_vm._s(_vm.$t("index.more"))+" ")])]),_c('div',{ref:"trainList_2",staticClass:"des school-list"},_vm._l((_vm.trainList_2),function(item,index){return _c('div',{key:item.id + 'school',ref:"school",refInFor:true,staticClass:"school-item",style:([
            {
              height:
                index == 0 ? _vm.itemHeight * 2 + 10 + 'px' : _vm.itemHeight + 'px',
            },
          ]),on:{"click":function($event){return _vm.openDetail('train', item)},"contextmenu":function($event){return _vm.openDetail('train', item)}}},[_c('a',{staticStyle:{"width":"100%","height":"100%"},attrs:{"href":"https://www.walson.net/#/searchorgan"}},[_c('img',{ref:"schoolImgs",refInFor:true,staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../assets/loading.gif"),"data-url":item.show_image}})])])}),0)]),_c('div',{staticClass:"house",attrs:{"id":"house"}},[_c('div',{staticClass:"house-title"},[_c('span',{staticClass:"houserent"},[_vm._v(" "+_vm._s(_vm.$t("index.school_district_housing"))+" ")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.to_housedetail}},[_vm._v(" "+_vm._s(_vm.$t("index.more"))+" ")])]),_c('div',{ref:"houseList",staticClass:"des school-list"},_vm._l((_vm.houseList),function(item,index){return _c('div',{key:item.id + 'school',ref:"school",refInFor:true,staticClass:"school-item",style:([
            {
              height: index == 0 ? _vm.firstHeight + 'px' : _vm.itemHeight + 'px',
            },
          ]),on:{"click":function($event){return _vm.openDetail('house', item)},"contextmenu":function($event){return _vm.openDetail('house', item)}}},[_c('a',{staticStyle:{"width":"100%","height":"100%"},attrs:{"href":"https://www.walson.net/#/housedetail"}},[_c('img',{ref:"schoolImgs",refInFor:true,staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../assets/loading.gif"),"data-url":item.show_image}})])])}),0)]),_c('div',{staticClass:"house",attrs:{"id":"active"}},[_c('div',{staticClass:"house-title"},[_c('span',{staticClass:"houserent"},[_vm._v(" "+_vm._s(_vm.$t("index.event_information"))+" ")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/activity')}}},[_vm._v(" "+_vm._s(_vm.$t("index.more"))+" ")])]),_c('div',{ref:"activeList",staticClass:"des school-list"},_vm._l((_vm.activeList),function(item,index){return _c('div',{key:item.id + 'school',ref:"school",refInFor:true,staticClass:"school-item",style:([
            {
              height: index == 0 ? _vm.firstHeight + 'px' : _vm.itemHeight + 'px',
            },
          ]),on:{"click":function($event){return _vm.openDetail('active', item)},"contextmenu":function($event){return _vm.openDetail('active', item)}}},[_c('a',{staticStyle:{"width":"100%","height":"100%"},attrs:{"href":item.url == 'events'
                ? 'https://www.walson.net/#/activity'
                : item.url == 'summer camp'
                ? 'https://www.walson.net/#/activity?id=1'
                : item.url == 'education fairs'
                ? 'https://www.walson.net/#/activity?id=2'
                : item.url == 'education salon'
                ? 'https://www.walson.net/#/activity?id=3'
                : 'https://www.walson.net/#/activity?id=4'}},[_c('img',{ref:"schoolImgs",refInFor:true,staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../assets/loading.gif"),"data-url":item.show_image}})])])}),0)]),(_vm.isleft_fixed)?_c('ul',{ref:"nav_left",staticClass:"nav-left",style:({ left: _vm.fixedWidth - 20 + 'px' })},[_c('li',[_c('a',{attrs:{"href":"#des"}},[_vm._v(_vm._s(_vm.$t("index.nav_1")))])]),_c('li',[_c('a',{attrs:{"href":"#train2"}},[_vm._v(_vm._s(_vm.$t("index.nav_2")))])]),_c('li',[_c('a',{attrs:{"href":"#house"}},[_vm._v(_vm._s(_vm.$t("index.nav_3")))])]),_c('li',[_c('a',{attrs:{"href":"#active"}},[_vm._v(_vm._s(_vm.$t("index.nav_4")))])]),_c('li',{on:{"click":_vm.back}},[_c('a',[_vm._v(_vm._s(_vm.$t("index.nav_5")))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }