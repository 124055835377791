<template>
  <div id="app">
    <header v-show="this.$store.state.isVisibleNav">
      <header-nav></header-nav>
    </header>
    <main>
      <router-view />
    </main>
    <login></login>
    <AllFooter
      v-show="this.$store.state.isVisibleNav"
      v-if="!isReload"
    ></AllFooter>
  </div>
</template>
<script>
import login from "@/components/login.vue";
import headerNav from "@/components/nav";
import AllFooter from "@/components/footer.vue";
export default {
  components: { headerNav, login, AllFooter },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: false,
    };
  },
  mounted() {
    this.$store.state.isVisibleNav = true;
  },
  methods: {
    reload() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
      //console.log("刷新视图");
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  header {
    width: 100%;
    background-color: #32485c;
    height: 60px;
  }
  main {
    flex: 1;
    margin-bottom: 20px;
  }
}
</style>
