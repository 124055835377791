export default {
    //导航
    nav: {
        home: "首页",
        about_us: "关于我们",
        international_schools: "国际学校",
        tutoring_courses: "辅导课程",
        school_district_housing: "学区房产",
        event_information: "活动资讯",
        contact_Us: "联系我们",
        personal_center: "个人中心",
        account_settings: "账户设置",
        log_out: "退出登录",
        login: "登录",
        Register: "注册",
        is_logout: "是否确认登出",
        reminder: "提示",
        confirm: "确定",
        cancel: "取消",
        cancelled: "已取消退出",
        logged_out: "退出成功"
    },
    // 登录
    login: {
        account_login: "密码登录",
        mobile_login: "手机登录",
        account_placeholder: "请输入手机号/邮箱",
        password_placeholder: "请输入密码",
        login: "登录",
        choose_placeholder: "请选择",
        mobile_placeholder: "请输入手机号",
        code_placeholder: "请输入验证码",
        forget_pwd: "忘记密码",
        register_now: "立即注册",
        agree: "继续即表示您同意",
        terms_of_use: "使用条款",
        read: "请阅读我们的",
        privacy_policy: "隐私政策",
        other_login: "通过第三方登录",
        register_title: "注册身份",
        email_register: "邮箱注册",
        mobile_register: "手机号注册",
        mobile: "手机号",
        email: "邮箱",
        email_placeholder: "请输入邮箱",
        code: "验证码",
        type: "会员类型",
        type_1: "培训机构",
        type_2: "房产中介",
        type_3: "家长",
        type_4: "老师",
        type_1_name: "机构名称",
        type_2_name: "中介名称",
        type_3_name: "昵称",
        type_4_name: "姓名",
        type_1_name_placeholder: "请输入机构名称",
        type_2_name_placeholder: "请输入中介名称",
        type_3_name_placeholder: "请输入昵称",
        type_4_name_placeholder: "请输入姓名",
        area: "所在地区",
        country_chose: "请选择国家",
        area_placeholder: "请设置所在地区",
        password: "密码",
        password_set: "请设置密码",
        skip: "暂时跳过",
        getCode: "获取验证码",
        time: "秒后重试",
        mobile_format_1: "请查看输入的手机号格式",
        account_format: "账号不能为空",
        password_format: "密码不能为空",
        mobile_format_2: "手机号不能为空",
        mobile_format_3: "请输入正确的手机号格式",
        code_format: "验证码不能为空",
        is_login: "登陆成功",
        verify: "验证信息"
    },
    index: {
        more: "更多",
        international_schools: "国际学校",
        tutoring_courses: "辅导课程",
        school_district_housing: "学区房产",
        event_information: "活动资讯",
        back_btn: "返回顶部",
        nav_1: "国际学校",
        nav_2: "辅导课程",
        nav_3: "学区房产",
        nav_4: "活动资讯",
        nav_5: "返回顶部"
    },
    about_us: {
        about_us: "关于我们",
        terms_of_use: "使用条款",
        privacy_policy: "隐私政策"
    },
    filter: {
        set_city_school: "按城市查看学校",
        set_course_school: "按课程体系查看学校",
        set_sort_course: "按类型查看课程",
        all_course: "全部课程",
        set_sort_house: "按类型查看房屋",
        all_sort: "全部类型",
        set_school_house: "按靠近学校查看房屋",
        chose_school: "请选择或输入学校",
        search: "点击搜索",
    },
    school: {
        school_key: "学校关键字",
        set_school_key: "请输入学校名称",
        area: "所在地区",
        course: "课程体系",
        set_course: "请选择课程体系",
        other_course: "课程详情",
        set_other_course: "请选择或输入课程名",
        level: "学部设置",
        set_level: "请选择学部",
        lang: "授课语言",
        set_lang: "请选择授课语言",
        lodge: "寄宿",
        lodge_1: "寄宿",
        lodge_2: "非寄宿",
        money: "学费范围 (单位：泰铢)",
        start_money: "最低学费",
        end_money: "最高学费",
        search: "搜索",
        other_school: "更多学校",
        school_list: "国际学校列表",
        find_1: "总共发现",
        find_2: "个国际学校",
        no_more: "没有更多了",
        back: "返回",
        school_detail: "学校详情",
        bookmark: "收藏",
        bookmarked: "已收藏",
        copy: "复制网址并分享",
        message: "重要信息",
        age: "招生年龄段",
        money_detail: "学费",
        lodge_detail: "寄宿制",
        time: "成立时间",
        about: "关于",
        location: "学校位置",
        comment: "评论",
        set_comment: "发布一条友善的评论",
        submit: "发布",
        no_more_commit: "没有更多评论",
        submit_success: "发布成功",
        copy_success: "已成功复制本页面网址",
        copy_warning: "复制失败"
    },
    train: {
        course_name: "课程名称",
        set_course_name: "请输入课程名称",
        area: "所在地区",
        sort: "课程类型",
        set_sort: "请选择课程类型",
        type: "机构类型",
        set_type: "请选择机构或私教",
        lang: "授课语言",
        set_lang: "请选择授课语言",
        is_online: "授课形式",
        set_is_online: "请选择线上或线下",
        class: "班制",
        set_class: "请选择一对一/小班",
        pay: "支付方式",
        set_pay: "请选择支付方式",
        age: "年龄范围",
        start_age: "最小年龄",
        end_age: "最大年龄",
        search: "搜索",
        more: "更多课程",
        list: "辅导课程列表",
        find_1: "总共发现 ",
        find_2: " 个辅导课程",
        no_more: "没有更多了",
        course_info: "课程信息",
        train_info: "机构信息",
        train_name: "机构名称",
        train_location: "机构所在地",
        train_email: "联系方式",
        train_www: "机构官网",
        teacher_info: "教师信息",
        teacher_name: "教师姓名",
        teacher_detail: "教师简介",
        teacher_type: "教师类型",
        teacher_lang_1: "母语教师",
        teacher_lang_2: "非母语教师",
        teacher_certificate: "资格证书",
        teacher_contact: "联系方式",
        teacher_email: "邮箱",
    },
    house: {
        location: "所在地区",
        type: "房屋类型",
        set_type: "请选择房屋类型",
        school: "靠近学校",
        set_school: "请选择靠近学校",
        money: "租金范围 (单位：泰铢)",
        min_money: "最低租金",
        max_money: "最高租金",
        area: "房屋面积",
        min_area: "最小面积",
        max_area: "最大面积",
        jiaju: "家具配置",
        set_jiaju: "请选择家具配置",
        time: "发布时间",
        search: "搜索",
        more: "更多信息",
        list: "学区房产列表",
        find_1: "总共发现 ",
        find_2: " 个学区房产",
        no_more: "没有更多了",
        mouth: "月",
        detail: "房屋详情",
        jiaju: "家具",
        type: "类型",
        room: "卧室数量",
    },
    active: {
        label: "概括",
        detail: "简介",
        picture_wall: "照片墙",
        address: "地址",
        get_address: "获取位置",
        type: "类型",
        time: "时间",
        start: "开始",
        end: "结束",
        copy_success: "已成功复制地址",
        copy_warning: "复制失败"
    },
    contact: {
        message: "请给我们留言，我们会很快回复您。",
        name_1: "姓氏",
        name_2: "名字",
        chose: "请选择",
        mobile: "请输入手机号",
        email: "邮箱",
        leave_message: "请在此留言",
        submit: "提交",
    },
    layout: {
        index: "首页",
        account: "账户设置",
        message: "发布信息",
        comment: "收藏评论",
    },
    account: {
        title: "账户设置",
        change: "更换 Logo 或 头像",
        fail: "图片加载失败",
        img_title: "点击更换用户头像",
        change_info: "修改个人资料",
        train_name: "机构名称",
        person_name: "中介名称",
        nickname: "昵称",
        teacher_name: "老师名称",
        member: "手机号",
        change_mobile_1: "修改手机",
        change_mobile_2: "绑定手机",
        email: "邮箱",
        change_email_1: "修改邮箱",
        change_email_2: "绑定邮箱",
        name: "姓名",
        set_name: "请输入姓名",
        area: "所在地区",
        user: "用户属性",
        user_type: "普通用户",
        password: "设置密码",
        save: "保存",
        change_password: "修改个人密码",
        old_pwd: "旧密码",
        new_pwd: "新密码",
        change_mobile: "修改手机号",
        code: "获取验证码",
        finish: "完成",
        placeholder: "请选择",
        new_mobile: "请输入新的手机号",
        set_code: "请输入验证码",
        button_1: "取消",
        button_2: "确定",
        change_email: "修改邮箱",
        new_email: "请输入邮箱",
        type_name_1: "培训机构",
        type_name_2: "房产中介",
        type_name_3: "家长",
        type_name_4: "老师",
        message: "个人资料未修改",
        up_img: "上传图片只能是 JPG 格式和 PNG 格式!",
        img_big: "上传图片大小不能超过 2MB!",
        password_title_1: "原密码不能为空",
        password_title_2: "新密码不能为空",
        password_title_3: "新密码不能与原密码相同",
        time: "秒后重试",
        mobile_type: "请查看输入的手机号格式"
    },
    message: {
        title: "发布信息",
        house: {
            label: "租房信息",
            id: "房源ID",
            type: "房屋类型",
            set_type: "请选择房屋类型",
            title: "标题",
            set_title: "请输入标题",
            area: "所在地区",
            set_area: "请选择所在地区",
            address: "具体地址",
            set_address: "请输入具体地址",
            school: "靠近学校",
            set_school: "请选择或输入学校",
            money: "租金(泰铢)",
            set_money: "请输入租金",
            size: "房屋面积",
            set_size: "请输入房屋面积",
            jiaju: "家具配置",
            set_jiaju: "请选择家具配置",
            room: "卧室数量",
            set_room: "选择卧室数量",
            detail: "房屋描述",
            img: "房屋图片",
            up_img: "已上传图片",
        },
        course: {
            label: "课程信息",
            name: "课程名称",
            set_name: "请输入课程名称",
            id: "课程ID",
            logo: "课程logo",
            area: "所在地区",
            set_area: "请选择所在地区",
            type: "课程类型",
            set_type: "请选择课程类型",
            train_type: "机构类型",
            set_train_type: "请选择机构类型",
            online: "授课形式",
            set_online: "请选择授课形式",
            lang: "授课语言",
            set_lang: "请选择授课语言",
            class_type: "班制",
            set_class_type: "请选择一对一/小班",
            pay: "支付方式",
            set_pay: "请选择支付方式",
            age: "年龄范围",
            min_age: "最小年龄",
            max_age: "最大年龄",
            info: "课程信息",
            img: "课程图片",
            up_img: "已上传图片"
        },
        train: {
            label: "机构信息",
            name: "名称",
            set_name: "请输入机构名称",
            area: "所在地区",
            set_area: "请选择国家",
            set_address: "请输入机构所在地区",
            contact: "联系方式",
            set_contact: "请输入机构邮箱",
            address: "地址",
            address_1: "请输入机构地址",
            web: "网址",
            set_web: "请输入机构官网网址",
            btn: "发布信息"
        },
        teacher: {
            label: "教师信息",
            name: "姓名",
            set_name: "请输入教师姓名",
            detail: "简介",
            set_detail: "请输入教师简介",
            lang: "母语老师",
            certificate: "资格证书",
            set_certificate: "请输入相关资格证书",
            contact: "联系方式",
            email: "邮箱",
            set_email: "请输入邮箱地址",
            other: "其他联系方式",
            set_other: "请输入联系方式",
            btn: "发布信息"
        },
        rules: {
            rule_1: "请选择房屋类型",
            rule_2: "请填入房屋标题",
            rule_3: "请选择所在国家和城市",
            rule_4: "请输入房屋地址",
            rule_5: "请填入房屋租金",
            rule_6: "请填入房屋面积",
            rule_7: "请选择家具配置",
            rule_8: "请选择卧室数量",
            rule_9: "请填入房屋信息",
            rule_10: "请输入机构名称",
            rule_11: "请上传机构logo",
            rule_12: "请选择机构或私教",
            rule_13: "请选择线上或线下",
            rule_14: "请选择授课语言",
            rule_15: "请选择课程类型",
            rule_16: "请选择一对一/小班",
            rule_17: "请选择支付方式",
            rule_18: "最少应有一个最小年龄",
            rule_19: "请填入课程信息",
            rule_20: "请先完善机构信息",
            rule_21: "请先完善教师信息",
        }
    },
    personal: {
        collect: "收藏评论",
        my_submit: "我的发布",
        my_comment: "我的评论",
        my_collect: "我的收藏",
        new_submit: "最新发布",
        course: "课程信息",
        time: "发布时间",
        detail: "查看详情",
        modify: "修改内容",
        del: "删除此条",
        no_course_message: "未发布任何课程信息",
        house: "租房信息",
        no_house_message: "未发布任何租房信息",
        mew_comment: "最新评论",
        school_comment: "学校评论",
        course_comment: "课程评论",
        no_comment: "未评论任何信息",
        school_title: "国际学校",
        course_title: "辅导课程",
        house_title: "学区房产",
        no_collect: "没有更多收藏了",
        del_title: "此操作将永久删除此条信息",
        is_del: "是否继续?",
        title: "提示",
        yes: "确定",
        no: "取消",
        del_success: "删除成功!",
        del_warn: "已取消删除"
    },
    password: {
        account: "账户设置",
        back: "返回首页",
        password: "找回密码",
        way: "请选择找回方式",
        mobile: "手机号",
        email: "邮箱",
        chose: "请选择",
        inner_email: "请输入邮箱",
        inner_mobile: "请输入手机号",
        inner_code: "请输入验证码",
        new_pwd: "请输入新密码",
        pwd_input: "确认密码",
        last_step: "上一步",
        next_step: "下一步",
        get_code: "获取验证码",
        finish: "完成",
        rules: {
            rule_1: "输入内容不能为空",
            rule_2: "两次密码输入必须一致",
            rule_3: "密码修改成功"
        }
    },
    status: {
        choose: "选择身份",
        choose_title: "选择您的沃森国际教育身份",
        choose_title_2: "首次登录需要选择您的沃森国际教育身份",
        chose_btn: "请选择您的身份",
        status_1: "培训机构",
        status_1: "房产中介",
        status_1: "家长",
        status_1: "老师",
        finish: "完成"
    },
    footer: {
        index: "首页",
        course: "辅导课程",
        active: "活动资讯",
        contact: "联系我们",
        school: "国际学校",
        school_1: "曼谷国际学校",
        school_2: "普吉岛国际学校",
        school_3: "清迈国际学校",
        house: "学区房产",
        house_1: "公寓",
        house_2: "联排别墅",
        house_3: "独栋别墅",
        weChat: "微信",
        text: "备案号",
        text_1: "使用条款",
        text_2: "隐私政策"
    }

}