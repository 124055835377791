import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
Vue.use(Vuex)

let ls = new SecureLS({
  encodingType: "aes",    //加密类型
  isCompression: false,
  encryptionSecret: "encryption",   //PBKDF2值
});

export default new Vuex.Store({
  state: {
    visibleLogin: false,
    visibleRegister: false,
    isVisibleNav: true,
    userInfo: {},
    sdawdascafawdfaw: "",
    info: {},
    searchInfo: {},
    google_id: "",
    facebook_id: "",
    fromFooter: false,
    lang: "zh"
  },
  getters: {
  },
  mutations: {
    CHANGE_LANG(state, value) {
      state.lang = value
    },
    CHANGE_VISIBLE_LOGIN(state, value) {
      state.visibleLogin = value
      // //console.log(value, state);
    },
    CHANGE_VISIBLE_REGISTER(state, value) {
      state.visibleRegister = value
      // //console.log(value, state);
    },
    CHANGE_VISIBLE_NAV(state, value) {
      if (value == "/mine" || value == "/mine/account" || value == "/mine/message" || value == "/password" || value == "/binding") {
        state.isVisibleNav = false
      } else {
        state.isVisibleNav = true
      }
      // //console.log(value, state);
    },
    STORAGE_USER_INFO(state, value) {
      state.userInfo = value
      // //console.log(value, state);
    },
    STORAGE_TOKEN(state, value) {
      state.sdawdascafawdfaw = value
      // //console.log(value, state);
    },
    GET_INFO(state, value) {
      state.info = value
    },
    SUBMIT_SEARCH_INFO(state, value) {
      // console.log(value)
      state.searchInfo = value
      // //console.log(state.searchInfo);
    },
    GET_FACEBOOK_ID(state, value) {
      state.facebook_id = value
    },
    GET_GOOGLE_ID(state, value) {
      state.google_id = value
    },
    SUBMIT_FROM_FOOTER(state, value) {
      state.fromFooter = value
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "encryptionStore",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ], //vuex持久化
})
