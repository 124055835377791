<template>
  <div>
    <!-- 登录 -->
    <el-dialog
      :visible.sync="this.$store.state.visibleLogin"
      :close-on-click-modal="false"
      :width="loginWidth"
      class="login"
      :before-close="handleClose"
    >
      <div class="login-box">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="$t('login.account_login')"
            name="account"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('login.mobile_login')"
            name="mobile"
          ></el-tab-pane>
        </el-tabs>
        <div class="login-form">
          <el-form
            v-if="activeName == 'account'"
            :model="accountForm"
            :rules="accountRules"
            ref="accountForm"
          >
            <el-form-item prop="account">
              <el-input
                size="medium"
                v-model="accountForm.account"
                :placeholder="$t('login.account_placeholder')"
                :validate-event="false"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                size="medium"
                v-model="accountForm.password"
                :placeholder="$t('login.password_placeholder')"
                :validate-event="false"
                type="password"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="login('accountForm')"
                size="medium"
                >{{ $t("login.login") }}</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            v-else
            :model="mobileForm"
            method="post"
            ref="mobileForm"
            :rules="mobileRules"
          >
            <el-form-item prop="mobile" class="register_item">
              <el-select
                size="medium"
                v-model="mobileForm.mobile_code"
                filterable
                :placeholder="$t('login.choose_placeholder')"
              >
                <el-option
                  v-for="(item, index) in code_list"
                  :key="item.cn + index"
                  :label="
                    $i18n.locale == 'zh'
                      ? item.code + ' ' + '(' + item.cn + ')'
                      : item.code + ' ' + '(' + item.en + ')'
                  "
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-input
                size="medium"
                v-model="mobileForm.mobile"
                :placeholder="$t('login.mobile_placeholder')"
                :validate-event="false"
              ></el-input>
            </el-form-item>
            <el-form-item class="code" prop="code">
              <el-input
                size="medium"
                v-model="mobileForm.code"
                :placeholder="$t('login.code_placeholder')"
                :validate-event="false"
              ></el-input>
              <el-button
                size="medium"
                plain
                @click.stop="getCode(mobileForm.mobile, mobileForm.mobile_code)"
                :disabled="code.disabled"
                >{{ code.text }}</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.stop="login('mobileForm')"
                size="medium"
                >{{ $t("login.login") }}</el-button
              >
            </el-form-item>
          </el-form>
          <div class="sign-in">
            <span class="item" @click="findPassword">
              {{ $t("login.forget_pwd") }}
            </span>
            <el-divider direction="vertical"></el-divider>
            <span class="item" @click="register">
              {{ $t("login.register_now") }}
            </span>
          </div>
          <div class="hint">
            {{ $t("login.agree") }}Walson Education{{
              $i18n.locale == "zh" ? "的" : "'s"
            }}<router-link target="_blank" to="/privacy/3">
              {{ $t("login.terms_of_use") }}</router-link
            >.{{ $t("login.read")
            }}<router-link target="_blank" to="/privacy/1">{{
              $t("login.privacy_policy")
            }}</router-link>
          </div>
          <el-divider content-position="center">
            {{ $t("login.other_login") }}
          </el-divider>

          <div class="login_methods_methods">
            <GoogleLogin
              :closeLoginForm="closeLoginForm"
              @googleLogin="googleLogin"
            ></GoogleLogin>
            <div class="login-third-btn facebook" @click="facebookLogin">
              <img src="../assets/facebook.png" />
              <span>Sign in with Facebook</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 注册 -->
    <el-dialog
      :visible.sync="this.$store.state.visibleRegister"
      :width="choseWidth"
      class="chose"
      :close-on-click-modal="false"
      :before-close="handleCloseType"
      append-to-body
      :title="$t('login.register_title')"
    >
      <div class="chose_reg">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="$t('login.mobile_register')"
            name="mobile"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('login.email_register')"
            name="email"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <el-form
        :label-position="labelPosition"
        label-width="100px"
        :model="registerForm"
      >
        <el-form-item
          v-show="activeName == 'mobile'"
          class="register_item"
          :label="$t('login.mobile')"
        >
          <el-select
            size="medium"
            v-model="registerForm.mobile_code"
            filterable
            :placeholder="$t('login.choose_placeholder')"
          >
            <el-option
              v-for="(item, index) in code_list"
              :key="index"
              :label="
                $i18n.locale == 'zh'
                  ? item.code + ' ' + '(' + item.cn + ')'
                  : item.code + ' ' + '(' + item.en + ')'
              "
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-input
            size="medium"
            v-model="registerForm.mobile"
            :placeholder="$t('login.mobile_placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="activeName == 'email'" :label="$t('login.email')">
          <el-input
            label-width="80px"
            size="medium"
            v-model="registerForm.email"
            :placeholder="$t('login.email_placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.code')" class="code" prop="code">
          <el-input
            size="medium"
            v-model="registerForm.code"
            :placeholder="$t('login.code_placeholder')"
          ></el-input>
          <el-button
            size="medium"
            plain
            @click.stop="login('signIn')"
            :disabled="code.disabled"
            >{{ code.text }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('login.type')">
          <el-radio-group v-model="registerForm.type">
            <el-radio :label="1">{{ $t("login.type_1") }}</el-radio>
            <el-radio :label="2">{{ $t("login.type_2") }}</el-radio>
            <el-radio :label="3">{{ $t("login.type_3") }}</el-radio>
            <el-radio :label="4">{{ $t("login.type_4") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="
            registerForm.type == 1
              ? $t('login.type_1_name')
              : registerForm.type == 2
              ? $t('login.type_2_name')
              : registerForm.type == 3
              ? $t('login.type_3_name')
              : $t('login.type_4_name')
          "
        >
          <el-input
            size="medium"
            v-model="registerForm.name"
            :placeholder="
              registerForm.type == 1
                ? $t('login.type_1_name_placeholder')
                : registerForm.type == 2
                ? $t('login.type_2_name_placeholder')
                : registerForm.type == 3
                ? $t('login.type_3_name_placeholder')
                : $t('login.type_4_name_placeholder')
            "
          ></el-input>
        </el-form-item>
        <el-form-item class="register_item" :label="$t('login.area')">
          <el-select
            size="medium"
            v-model="registerForm.country"
            filterable
            :placeholder="$t('login.country_chose')"
            :style="{ width: windowWidth > 768 ? '' : '50%' }"
          >
            <el-option
              v-for="(item, index) in country_list"
              :key="item + index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            size="medium"
            v-model="registerForm.address"
            :placeholder="$t('login.area_placeholder')"
            :style="{ width: windowWidth > 768 ? '' : '50%' }"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.password')">
          <el-input
            size="medium"
            v-model="registerForm.password"
            :placeholder="$t('login.password_set')"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="register_button">
          <el-button
            type="primary"
            plain
            @click.stop="handleCloseType"
            size="medium"
            >{{ $t("login.skip") }}</el-button
          >
          <el-button type="success" plain @click.stop="submit" size="medium">{{
            $t("login.register_now")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <SlideVerify
      v-if="isVisible"
      :openBtn="openBtn"
      @slideSuccess="slideSuccess"
    ></SlideVerify>
  </div>
</template>
<script>
import {
  mobileLogin,
  getMobileCode,
  accountLogin,
  getRegister,
  getFacebookInfo,
  getGoogleInfo,
  getEmailCode,
} from "@/API/index";
import { codeList, countryLIstCN, countryLIstEN } from "@/assets/js/country";
import GoogleLogin from "../components/googleLogin.vue";
import SlideVerify from "./slideVerify.vue";
export default {
  components: { GoogleLogin, SlideVerify },
  data() {
    return {
      code_list: [],
      country_list: [],
      // 登录区域是否显示
      show_login_box: this.$store.state.visibleLogin,
      dialogVisible: false,
      labelPosition: "left",
      activeName: "account",
      accountForm: { account: "", password: "" },
      registerForm: { mobile_code: "+86", type: 1 },
      code: {
        disabled: false,
        text: this.$t("login.getCode"),
      },
      regCode: "",
      choseWidth: "40%",
      loginWidth: "580px",
      mobileForm: { mobile_code: "+86" },
      userInfo: {},
      accountRules: {
        account: [{ required: true, validator: this.checkAccount }],
        password: [{ required: true, validator: this.checkAccountPwd }],
      },
      mobileRules: {
        mobile: [
          { required: true, validator: this.checkMobile, trigger: "blur" },
        ],
        code: [{ required: true, validator: this.checkCode }],
      },
      facebook_id: "",
      google_id: "",
      windowWidth: 0,
      time: 0,
      isVisible: false,
      openBtn: "",
      form: {},
      activeName: "mobile",
      IP: false,
    };
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    this.code_list = codeList.list;
    this.country_list =
      this.$i18n.locale == "zh" ? countryLIstCN : countryLIstEN;
    if (document.documentElement.clientWidth > 768) {
      this.choseWidth = "40%";
      this.loginWidth = this.$i18n.locale == "zh" ? "580px" : "700px";
      this.labelPosition = "left";
    } else {
      this.choseWidth = "100%";
      this.loginWidth = "100%";
      this.labelPosition = "top";
    }
    this.closeLoginForm();
    // this.getCountry();
    // console.log(this.$store.state.visibleLogin);
    this.loadFacebookJS();
    this.loadGoogleJS();
  },

  updated() {
    // // console.log(this.$store.state.visibleLogin);
    // this.refresh();
  },
  methods: {
    // async refresh() {
    //   if (this.$store.state.visibleLogin) {
    //     await this.loadJS();
    //     setTimeout(() => {
    //       this.$nextTick(() => {
    //         this.IP = true;
    //         // console.log("22222222222222");
    //       });
    //     }, 500);
    //   }
    // },
    // 引入js文件
    async loadGoogleJS() {
      const s1 = document.createElement("script");
      s1.type = "text/javascript";
      s1.src = "https://accounts.google.com/gsi/client";
      await document.body.appendChild(s1);
      const s2 = document.createElement("script");
      //   s2.type = "text/javascript";
      //   s2.innerHTML = `window.fbAsyncInit = function () {
      //   // //console.log('s11111');
      //   FB.init({
      //     appId: '1165768024141392',//facebook申请的的appid
      //     cookie: true, // enable cookies to allow the server to access
      //     xfbml: true, // parse social plugins on this page
      //     version: 'v2.8' // use graph api version 2.8
      //   });
      // };
      // //引入Facebook的sdk
      // (function (d, s, id) {
      //   var js, fjs = d.getElementsByTagName(s)[0];
      //   if (d.getElementById(id)) return;
      //   js = d.createElement(s);
      //   js.id = id;
      //   js.src = "https://connect.facebook.net/zh_CN/sdk.js";
      //   fjs.parentNode.insertBefore(js, fjs);
      // }(document, 'script', 'facebook-jssdk'));;`;
      //   await document.body.appendChild(s2);
      // console.log("11111111");
    },
    async loadFacebookJS() {
      const s2 = document.createElement("script");
      s2.type = "text/javascript";
      s2.innerHTML = `window.fbAsyncInit = function () {
      // //console.log('s11111');
      FB.init({
        appId: '1165768024141392',//facebook申请的的appid
        cookie: true, // enable cookies to allow the server to access 
        xfbml: true, // parse social plugins on this page
        version: 'v2.8' // use graph api version 2.8
      });
    };
    //引入Facebook的sdk
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/zh_CN/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));;`;
      await document.body.appendChild(s2);
    },
    // async getCountry() {
    //   let vue_this = this;
    //   let onSuccess = function (geoipResponse) {
    //     /* There's no guarantee that a successful response object
    //      * has any particular property, so we need to code defensively. */
    //     // console.log(geoipResponse.country.iso_code);
    //     if (!geoipResponse.country.iso_code) {
    //       return;
    //     }
    //     /* ISO country codes are in upper case. */
    //     vue_this.IP = geoipResponse.country.iso_code.toLowerCase();
    //     vue_this.$store.commit("SET_IP", vue_this.IP);
    //     if (vue_this.IP != "cn") {
    //       vue_this.loadJS();
    //     }
    //   };
    //   let onError = function (error) {};
    //   let getIP = () => {
    //     geoip2.country(onSuccess, onError);
    //   };
    //   await getIP();
    // },
    // 关闭登录
    closeLoginForm() {
      this.$store.commit("CHANGE_VISIBLE_LOGIN", false);
      this.mobileForm = { mobile_code: "+86" };
      this.accountForm = {};
    },
    handleClose() {
      this.$store.commit("CHANGE_VISIBLE_LOGIN", false);
    },
    handleCloseType() {
      this.$store.commit("CHANGE_VISIBLE_REGISTER", false);
    },
    //!显示注册
    register() {
      this.$store.commit("CHANGE_VISIBLE_REGISTER", true);
      this.closeLoginForm();
    },
    async getCode(mobile, mobile_code) {
      let quhao = mobile_code.substr(1);
      // //console.log(this.mobileForm.mobile,"1111111");
      if (/^\d+$/.test(mobile)) {
        await getMobileCode({ quhao, mobile }).then((res) => {
          this.regCode = res.data.data.code;
          // this.mobileForm.code = res.data.data.code;
          //console.log(this.regCode);
        });
        this.time = 59;
        let setTime;
        setTime = setInterval(() => {
          if (this.time > 0) {
            if (this.$i18n.locale == "zh") {
              this.code.text = this.time + this.$t("login.time");
            } else {
              this.code.text = this.time + "秒后重试";
            }

            this.time--;
          } else {
            clearInterval(setTime);
            this.code.disabled = false;
            this.code.text = this.$t("login.getCode");
            this.time = 59;
          }
        }, 1000);
        this.code.disabled = true;
      } else {
        this.$message.error(this.$t("login.mobile_format_1"));
      }
    },
    async getEmail(email) {
      let data = {
        email: email,
        type: 1,
      };
      await getEmailCode(data).then((res) => {});
      this.time = 59;
      let setTime;
      setTime = setInterval(() => {
        if (this.time > 0) {
          if (this.$i18n.locale == "zh") {
            this.code.text = this.time + this.$t("login.time");
          } else {
            this.code.text = this.time + "秒后重试";
          }
          this.time--;
        } else {
          clearInterval(setTime);
          this.code.disabled = false;
          this.code.text = this.$t("login.getCode");
          this.time = 59;
        }
      }, 1000);
      this.code.disabled = true;
    },
    login(key) {
      this.isVisible = true;
      this.openBtn = key;
    },
    async loginBtn(key) {
      await this.$refs[key].validate((value) => {
        if (value) {
          if (key === "mobileForm") {
            this.mobileForm.quhao = this.mobileForm.mobile_code.substr(1);
            delete this.mobileForm.mobile_code;
            this.mobileForm.google_id = this.google_id;
            this.mobileForm.facebook_id = this.facebook_id;
            mobileLogin(this.mobileForm).then((res) => {
              //console.log(res.data.data, "111111111");
              this.userInfo = res.data.data;
              this.$store.commit("STORAGE_TOKEN", res.data.data.token);
              this.$store.commit("STORAGE_USER_INFO", this.userInfo);
              this.$message({
                type: "success",
                message: res.data.msgstr,
              });
              this.$store.commit("CHANGE_VISIBLE_LOGIN", false);
              this.time = 0;
              // if (this.userInfo.type == 0) {
              //   this.dialogVisible = true;
              // } else {
              //   this.dialogVisible = false;
              // }
            });
            this.code.disabled = false;
            this.time = 0;
            this.mobileForm = { mobile_code: "+86" };
          } else if (key === "accountForm") {
            this.accountForm.google_id = this.google_id;
            this.accountForm.facebook_id = this.facebook_id;
            accountLogin(this.accountForm).then((res) => {
              // //console.log(res.data.data, "111111111");
              this.userInfo = res.data.data;
              this.$store.commit("STORAGE_TOKEN", res.data.data.token);
              this.$store.commit("STORAGE_USER_INFO", this.userInfo);
              this.$message({
                type: "success",
                message: res.data.msgstr,
              });
              this.$store.commit("CHANGE_VISIBLE_LOGIN", false);
              // if (this.userInfo.type == 0) {
              //   this.dialogVisible = true;
              // } else {
              //   this.dialogVisible = false;
              // }
              this.accountForm = {};
            });
          }
        } else {
          return;
        }
      });
    },
    slideSuccess(value) {
      this.isVisible = false;
      if (value == "mobileForm" || value == "accountForm") {
        this.loginBtn(value);
      } else if (value == "signIn") {
        this.activeName == "mobile"
          ? this.getCode(
              this.registerForm.mobile,
              this.registerForm.mobile_code
            )
          : this.getEmail(this.registerForm.email);
      }
    },
    // 注册
    async submit() {
      if (this.activeName == "mobile") {
        this.registerForm.reg_type = 1;
      } else if (this.activeName == "email") {
        this.registerForm.reg_type = 2;
      }
      //console.log(this.registerForm.reg_type);
      this.registerForm.quhao = this.registerForm.mobile_code.substr(1);
      delete this.registerForm.mobile_code;
      // //console.log(this.registerForm);
      await getRegister(this.registerForm).then((res) => {
        this.userInfo = res.data.data;
        this.$store.commit("STORAGE_TOKEN", res.data.data.token);
        this.$store.commit("STORAGE_USER_INFO", this.userInfo);
        this.$message({
          type: "success",
          message: res.data.msgstr,
        });
      });
      this.registerForm = { mobile_code: "+86" };
      this.$store.commit("CHANGE_VISIBLE_REGISTER", false);
    },
    // 账号
    checkAccount(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t("login.account_format")));
      } else {
        callback();
      }
    },
    // 密码
    checkAccountPwd(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t("login.password_format")));
      } else {
        callback();
      }
    },
    // 手机号
    checkMobile(rule, value, callback) {
      //console.log(value);
      let reg = /^\d+$/;
      if (!value) {
        callback(new Error(this.$t("login.mobile_format_2")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t("login.mobile_format_3")));
      } else {
        callback();
      }
    },
    // 手机验证码
    checkCode(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t("login.code_format")));
      } else {
        callback();
      }
    },
    async facebookLogin() {
      let vue_this = this;

      // 检测登录状态
      FB.getLoginStatus(function (response) {
        if (response.status == "connected") {
          FB.logout(function (res) {
            // //console.log(res);
          });
          vue_this.$store.commit("GET_FACEBOOK_ID", "");
        }
      });
      FB.login(
        function (response) {
          vue_this.statusChangeCallBack(response);
        },
        { scope: "email," }
      );
    },
    async statusChangeCallBack(response) {
      let vue_this = this;
      if (response.status == "connected") {
        vue_this.facebook_id = response.authResponse.userID;
        FB.api("/me?fields=name,email", function (response) {
          // //console.log(response, "facebook返回的信息");
          vue_this.form = response;
          FB.api(
            response.id + "/picture",
            "GET",
            { redirect: "false" },
            function (pic) {
              // //console.log(pic, "facebook返回的头像信息");
              vue_this.form.url = pic.data.url;
              //console.log(vue_this.form);
            getFacebookInfo(vue_this.form).then((res) => {
                vue_this.userInfo = res.data.data;
                vue_this.$store.commit("STORAGE_TOKEN", res.data.data.token);
                vue_this.$store.commit("STORAGE_USER_INFO", vue_this.userInfo);
                if (res.data.data.type == 0) {
                  vue_this.$router.push("/binding");
                } else {
                  vue_this.$message.success(this.$t("login.is_login"));
                }
              });
              vue_this.closeLoginForm();
            }
          );
        });
      }
    },
    // googleLogin() {
    //   const client = google.accounts.oauth2.initTokenClient({
    //     client_id:
    //       "592187663113-553jkmrhvm3a49v40lbi5b029sac70t5.apps.googleusercontent.com", //google申 请的appid
    //     scope: "profile email openid", //需要google返回的数据
    //     callback: (response) => {
    //       this.google_id = response.credential.sub;
    //       //console.log(response, this.google_id);
    //       getGoogleInfo({ google_id: this.google_id }).then((res) => {
    //         if (res.data.data.token != "") {
    //           this.userInfo = res.data.data;
    //           this.$store.commit("STORAGE_TOKEN", res.data.data.token);
    //           this.$store.commit("STORAGE_USER_INFO", this.userInfo);
    //         } else {
    //         }
    //         this.closeLoginForm();
    //       });
    //       // buttonlogn("2", response.access_token); //返回给后端access_token
    //     }, //回调函数
    //   });
    //   client.requestAccessToken();
    // },

    googleLogin(info) {
      this.userInfo = info;
      this.$store.commit("STORAGE_TOKEN", info.token);
      this.$store.commit("STORAGE_USER_INFO", this.userInfo);
      this.$message.success("登陆成功");
      this.closeLoginForm();
    },

    findPassword() {
      this.$router.push("/password");
      this.$store.commit("CHANGE_VISIBLE_NAV", "/password");
      this.closeLoginForm();
    },
  },
};
</script>
<style scoped lang='less'>
a {
  text-decoration: none;
  font-size: 18px;
  color: black;
}
// 登录窗口样式
.login_box {
  // border: 1px solid #ccc;
  box-sizing: border-box;
  box-shadow: 0 0 5px #ccc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(254, 255, 254);
  width: 480px;
  height: 390px;
  display: flex;
  flex-direction: column;
}
.login_count_form {
  width: 300px;
  margin: 0 auto;
}
.login_img {
  padding: 5px;
  background-color: rgb(73, 163, 236);
}
.login_img > img {
  width: 30px;
}
.login_title {
  padding-top: 10px;

  display: flex;
  justify-content: space-around;
}
.count-left {
  border-bottom: 1px solid rgb(96, 171, 241);
  height: 25px;
}
.count-m {
  border-bottom: 1px solid rgb(96, 171, 241);
  height: 25px;
}
.login_message {
  display: flex;
  flex-direction: column;
  // text-align: center;
}
.login_message input {
  outline: none;
  padding-left: 5px;
}
.login_message_div {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.login_message_name {
  width: 290px;
  height: 30px;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.login_message_password {
  width: 200px;
  height: 30px;
  border: 1px solid #ccc;
  // text-align:left;
}
.login_message_div a {
  font-size: 14px;
  display: inline-block;
  margin: 6px 15px;
  color: rgb(104, 183, 248);
  cursor: pointer;
}
.login_message_ps {
  display: flex;
  margin-bottom: 10px;
}
.login_code {
  margin-left: 8px;
}
.count_code {
  width: 190px;
}
.login_code > img {
  width: 70px;
  height: 30px;
  border: 1px solid #ccc;
}
.login_message_ps a {
  font-size: 14px;
  display: inline-block;
  margin: 6px 17px;
  color: rgb(104, 183, 248);
  cursor: pointer;
}

.login_message_code {
  width: 120px;
  height: 30px;
  border: 1px solid #ccc;
}
.login_message > button {
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background-color: rgb(73, 163, 236);
  color: #f0f0f0;
  font-size: 15px;
  cursor: pointer;
}
.login_message > button:hover {
  background-color: rgb(57, 53, 253);
}
.login_methods {
  display: flex;
  flex-direction: column;
}
.login_method_title {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 13px;
  color: rgb(138, 136, 136);
}
.login_methods_methods {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  .login-third-btn {
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    img {
      height: 100%;
      transform: translateX(-4px);
    }
  }
}
.close_login_form {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
// .close2{
//   position: absolute;
//   right: 50%;
//   cursor: pointer;
// }
.login {
  .login-box {
    width: 60%;
    margin: auto;
    /deep/.el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-dialog__header {
      display: none;
    }
    .login-form {
      /deep/.el-button {
        width: 100%;
      }

      .sign-in {
        display: flex;
        justify-content: space-evenly;
        .item {
          color: #ccc;
          cursor: pointer;
        }
        .item:hover {
          color: #409eff;
        }
      }
    }
  }
}
.code {
  /deep/.el-form-item__content {
    display: flex;
  }
  /deep/.el-input {
    width: 60%;
  }
  /deep/.el-button {
    width: 36% !important;
    margin-left: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.chose {
  /deep/.el-dialog {
    border-radius: 20px;
    .el-button {
      width: 100%;
    }
    /deep/.el-dialog__headerbtn {
      display: none;
    }
    .dialog-item {
      width: 100%;
      height: 200px;
      border-radius: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
.chose_reg {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  div:first-child {
    margin-right: 20px;
  }
}
.register_item {
  /deep/ .el-form-item__content {
    display: flex;
    .el-select {
      width: 40%;
      margin-right: 10px;
    }
  }
}
.register_button {
  width: 100%;
  display: flex;
  position: relative;
  /deep/.el-form-item__content {
    margin-left: 0 !important;
    width: 100%;
    display: flex;
  }
}
.slide_box {
  position: relative;
}
.hint {
  font-size: 14px;
  margin: 15px 0 10px;
  a {
    font-size: 14px;
    color: #606266;
    border-bottom: 1px solid #606266;
  }
  a:hover {
    color: #000;
    border-bottom: 1px solid #000;
  }
}

@media screen and (max-width: 768px) {
  .login .login-box {
    width: 100%;
  }
}
</style>