import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/view/index.vue'
import about from '@/view/about/about.vue'
import relation from '@/view/relation.vue'
import store from '@/store/index'
import { Message } from "element-ui"
// /* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// // 先存储默认底层的push
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, resolve, reject) {
//   if (resolve || reject) return originalPush.call(this, location, resolve, reject)
//   return originalPush.call(this, location).catch((e) => { })
// }

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: index,
    redirect: '/index'
  }, {
    path: "/index",
    component: index
  },
  {
    path: "/filter/:key",
    component: () => import("@/view/filter/filter.vue")
  },
  {
    path: '/school',
    name: 'school',
    component: () => import("@/view/school/School.vue")
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/relation',
    name: 'relation',
    component: relation
  },
  {
    path: '/activity',
    component: () => import("@/view/activity/Active.vue")
  },
  {
    path: '/activity/detail/:id',
    component: () => import("@/view/activity/ActiveDetail.vue")
  },
  {
    path: '/housedetail',
    component: () => import("@/view/house/House.vue"),
  },
  {
    path: '/mine',
    name: 'mine',
    beforeEnter: (to, from, next) => {
      if (!store.state.sdawdascafawdfaw) {
        setTimeout(() => {
          next("/index")
          store.commit("CHANGE_VISIBLE_NAV", "/index")
        }, 1000);
        Message.warning("请登录账号")
      } else {
        next()
      }
    },
    component: () => import("@/view/mine/Layout.vue"),
    children: [{
      path: "/",
      component: () => import("@/view/mine/Personal.vue")
    }, {
      path: "/mine/account",
      component: () => import("@/view/mine/Account.vue")
    }, {
      path: "/mine/message",
      component: () => import("@/view/mine/Message.vue")
    }]
  },
  {
    path: '/searchorgan',
    name: 'searchorgan',
    component: () => import("@/view/organization/SearchOrgan.vue")
  },
  {
    path: '/detail/:id',
    component: () => import("@/view/organization/OrganizationDetail.vue")
  },

  {
    path: '/searchrent/:id',
    name: 'searchrent',
    component: () => import("@/view/house/HouseDetail.vue")
  },
  {
    path: '/schooldetail/:id',
    name: 'schooldetail',
    component: () => import("@/view/school/SchoolDetail.vue")
  },
  {
    path: '/privacy/:type',
    component: () => import("@/view/about/privacy.vue")
  }, {
    path: '/password',
    component: () => import("@/view/find/password.vue")
  }, {
    path: '/binding',
    component: () => import("@/view/mine/binding.vue")
  }
]

const router = new VueRouter({
  routes
})
// 设置全局前置守卫
// router.beforeEach((to, from, next) => {
//   let token = Store.state.sdawdascafawdfaw
//   if (to.path == "/mine") {
//     if (token) {
//       next()
//     } else {
//       if (to.path == "/") {
//         next()
//       }
//       next("/")
//     }
//   }
// })
export default router
