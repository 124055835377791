import request from "@/utils/request";
// 封装用户相关API

// 获取logo图
export const getLogo = (data) => { return request({ url: "api/api/getSystem", method: "post", data}) }
// 获取banner图
export const getBanner = (data) => { return request({ url: "/api/index/banner", method: "post", data }) }
// 登录
export const getIndexInfo = (data) => { return request({ url: "/api/index/index", method: "post", data }) }
// facebook登录
export const getFacebookInfo = (data) => { return request({ url: "/api/login/facebook", method: "post", data }) }
// google登录
export const getGoogleInfo = (data) => { return request({ url: "/api/login/google", method: "post", data }) }
// 注册
export const getRegister = (data) => { return request({ url: "/api/login/register", method: "post", data }) }
// 学校搜索项
export const getSchoolSearch = (data) => { return request({ url: "/api/schools/search", method: "post", data }) }
// 获取学校列表
export const getSchoolList = (data) => { return request({ url: "/api/schools/index", method: "post", data }) }
// 获取学校详情
export const getSchoolDetail = (data) => { return request({ url: "/api/schools/detail", method: "post", data }) }
// 收藏
export const addCollect = (data) => { return request({ url: "/api/my/addcollect", method: "post", data }) }
// 获取学校评论
export const getSchoolComment = (data) => { return request({ url: "/api/schools/comment", method: "post", data }) }
// 获取验证码
export const getMobileCode = (data) => { return request({ url: "/api/login/sendSms", method: "post", data }) }
export const getEmailCode = (data) => { return request({ url: "/api/login/sendemail", method: "post", data }) }
export const resetPassword = (data) => { return request({ url: "/api/login/resetpwd", method: "post", data }) }
// 手机号登录
export const mobileLogin = (data) => { return request({ url: "/api/login/mobile", method: "post", data }) }
// 账号登录
export const accountLogin = (data) => { return request({ url: "api/login/account", method: "post", data }) }
// 活动分类
export const activeClass = (data) => { return request({ url: "/api/Activitys/search", method: "post", data }) }
// 活动列表
export const activeList = (data) => { return request({ url: "/api/Activitys/index", method: "post", data }) }
// 活动详情
export const activityDetail = (data) => { return request({ url: "/api/Activitys/detail", method: "post", data }) }
// 培训机构列表
export const getTrainList = (data) => { return request({ url: "/api/trains/index", method: "post", data }) }
// 培训机构搜索项
export const getTrainSearch = (data) => { return request({ url: "/api/trains/search", method: "post", data }) }
// 培训机构详情
export const getTrainDetail = (data) => { return request({ url: "/api/trains/detail", method: "post", data }) }
// 培训机构评论
export const getTrainComment = (data) => { return request({ url: "/api/trains/comment", method: "post", data }) }
// 租房列表
export const getHouseList = (data) => { return request({ url: "/api/houses/index", method: "post", data }) }
// 租房搜索项
export const getHouseSearch = (data) => { return request({ url: "/api/houses/search", method: "post", data }) }
// 租房详情页
export const getHouseDetail = (data) => { return request({ url: "/api/houses/detail", method: "post", data }) }
// 我的信息
export const getMineInfo = (data) => { return request({ url: "/api/my/index", method: "post" ,data}) }
// 改变信息
export const changeMine = (data) => { return request({ url: "/api/my/update", method: "post", data }) }
// 发布/编辑-国际学校
export const addSchool = (data) => { return request({ url: "/api/my/addschool", method: "post", data }) }
// 发布/编辑-租房
export const addHouse = (data) => { return request({ url: "/api/my/addHouse", method: "post", data }) }
// 发布/编辑-培训机构
export const addTrain = (data) => { return request({ url: "/api/my/addTrain", method: "post", data }) }
// 删除租房
export const delHouse = (data) => { return request({ url: "/api/my/delHouse", method: "post", data }) }
// 删除培训机构
export const delTrain = (data) => { return request({ url: "/api/my/delTrain", method: "post", data }) }
// 删除学校
export const delSchool = (data) => { return request({ url: "/api/my/delSchool", method: "post", data }) }
// 修改密码
export const updatePwd = (data) => { return request({ url: "/api/my/updatePwd", method: "post", data }) }
// 我的评论
export const myComment = (data) => { return request({ url: "/api/my/comment", method: "post", data }) }
// 删除评论
export const delComment = (data) => { return request({ url: "/api/my/delComment", method: "post", data }) }
// 发表评论
export const addComment = (data) => { return request({ url: "api/my/addComment", method: "post", data }) }
// 选择身份
export const choseID = (data) => { return request({ url: "api/my/update", method: "post", data }) }
// 获取我的收藏
export const myCollect = (data) => { return request({ url: "api/my/collect", method: "post", data }) }
// 点赞或点踩
export const isLike = (data) => { return request({ url: "api/my/addzancai", method: "post", data }) }
// 联系我们
export const contactUs = (data) => { return request({ url: "api/index/contact", method: "post", data }) }
// 获取文章
export const getText = (data) => { return request({ url: "api/index/detail", method: "post", data }) }
// 添加教师信息
export const getTeacher = (data) => { return request({ url: "api/my/addteacher", method: "post", data }) }
// 添加机构信息
export const getTrain = (data) => { return request({ url: "api/my/addtraininfo", method: "post", data }) }
// 获取教师/机构信息
export const get_train_teacher = (data) => { return request({ url: "api/index/getotherinfo", method: "post", data }) }
// 获取谷歌id
export const getGoogle = (data) => { return request({ url: "api/api/getgoogle", method: "post", data }) }