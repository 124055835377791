export default {
    //导航
    nav: {
        home: "หน้าแรก",
        about_us: "เกี่ยวกับพวกเรา",
        international_schools: "โรงเรียนนานาชาติ",
        tutoring_courses: "หลักสูตรกวดวิชา",
        school_district_housing: "อสังหาริมทรัพย์ย่านโรงเรียน",
        event_information: "ข้อมูลกิจกรรม",
        contact_Us: "ติดต่อพวกเรา",
        personal_center: "ศูนย์กลางส่วนบุคคล",
        account_settings: "ตั้งค่าบัญชี",
        log_out: "ออกจากระบบ",
        login: "เข้าสู่ระบบ",
        Register: "ลงทะเบียน",
        is_logout: "คุณแน่ใจหรือที่จะออกจากระบบ",
        reminder: "คำใบ้",
        confirm: "ยืนยัน",
        cancel: "ยกเลิก",
        cancelled: "ยกเลิกการออกจากระบบแล้ว",
        logged_out: "ออกจากระบบสำเร็จ"
    },
    // 登录
    login: {
        account_login: "เข้าสู่ระบบด้วยรหัส",
        mobile_login: "เข้าสู่ระบบด้วยโทรศัพท์",
        account_placeholder: "กรุณากรอกหมายเลขโทรศัพท์/อีเมล",
        password_placeholder: "กรุณากรอกรหัสผ่าน",
        login: "เข้าสู่ระบบ",
        choose_placeholder: "กรุณาเลือก",
        mobile_placeholder: "กรุณากรอกหมายเลขโทรศัพท์",
        code_placeholder: "กรุณากรอกรหัสยืนยัน",
        forget_pwd: "ลืมรหัสผ่าน",
        register_now: "ลงทะเบียนทันที",
        agree: "ดำเนินการต่อแสดงถึงการเห็นด้วย",
        terms_of_use: "ข้อกำหนดการใช้งาน",
        read: "กรุณาอ่านเอกสารของเรา:",
        privacy_policy: "นโยบายความเป็นส่วนตัว",
        other_login: "เข้าสู่ระบบผ่านบุคคลที่สาม",
        register_title: "สถานะการลงทะเบียน",
        email_register: "ลงทะเบียนด้วยอีเมล",
        mobile_register: "ลงทะเบียนด้วยหมายเลขโทรศัพท์",
        mobile: "หมายเลขโทรศัพท์",
        email: "อีเมล",
        email_placeholder: "กรุณากรอกอีเมล",
        code: "รหัสยืนยัน",
        type: "ประเภทสมาชิก",
        type_1: "สถาบันฝึกอบรม",
        type_2: "นายหน้าอสังหาริมทรัพย์",
        type_3: "ผู้ปกครอง",
        type_4: "คุณครู",
        type_1_name: "ชื่อสถาบัน",
        type_2_name: "ชื่อนายหน้า",
        type_3_name: "ชื่อเล่น",
        type_4_name: "ชื่อ-นามสกุล",
        type_1_name_placeholder: "กรุณากรอกชื่อสถาบัน",
        type_2_name_placeholder: "กรุณากรอกชื่อนายหน้า",
        type_3_name_placeholder: "กรุณากรอกชื่อเล่น",
        type_4_name_placeholder: "กรุณากรอกชื่อ-นามสกุล",
        area: "ที่ตั้ง",
        country_chose: "กรุณาเลือกประเทศ",
        area_placeholder: "กรุณาตั้งค่าภูมิภาค",
        password: "รหัสผ่าน",
        password_set: "กรุณาตั้งรหัสผ่าน",
        skip: "ข้ามไปก่อน",
        getCode: "รับรหัสยืนยัน",
        time_1: "ลองอีกครั้งหลังจาก ",
        time_2: " วินาที",
        mobile_format_1: "กรุณาตรวจสอบรูปแบบหมายเลขโทรศัพท์ที่กรอก",
        account_format: "บัญชีไม่สามารถเว้นว่างได้",
        password_format: "รหัสผ่านไม่สามารถเว้นว่างได้",
        mobile_format_2: "หมายเลขโทรศัพท์ไม่สามารถเว้นว่างได้",
        mobile_format_3: "กรุณากรอกรูปแบบหมายเลขโทรศัพท์ให้ถูกต้อง",
        code_format: "รหัสยืนยันไม่สามารถเว้นว่างได้",
        is_login: "เข้าสู่ระบบสำเร็จ",
        verify: "ข้อความยืนยัน"
    },
    index: {
        more: "มากกว่านี้",
        international_schools: "โรงเรียนนานาชาติ",
        tutoring_courses: "หลักสูตรกวดวิชา",
        school_district_housing: "อสังหาริมทรัพย์ย่านโรงเรียน",
        event_information: "ข้อมูลกิจกรรม",
        back_btn: "กลับไปด้านบนสุด",
        nav_1: "โรงเรียนนานาชาติ",
        nav_2: "หลักสูตรกวดวิชา",
        nav_3: "ที่พัก",
        nav_4: "ข้อมูลกิจกรรม",
        nav_5: "กลับไปด้านบนสุด"
    },
    about_us: {
        about_us: "เกี่ยวกับพวกเรา",
        terms_of_use: "ข้อกำหนดการใช้งาน",
        privacy_policy: "นโยบายความเป็นส่วนตัว"
    },
    filter: {
        set_city_school: "ค้นหาโรงเรียนตามเมือง",
        set_course_school: "ค้นหาโรงเรียนตามหลักสูตร",
        set_sort_course: "ค้นหาหลักสูตรตามประเภท",
        all_course: "หลักสูตรทั้งหมด",
        set_sort_house: "ค้นหาที่พักตามประเภท",
        all_sort: "ทุกประเภท",
        set_school_house: "ค้นหาที่พักใกล้โรงเรียน",
        chose_school: "กรุณาเลือกหรือใส่โรงเรียน",
        search: "คลิกเพื่อค้นหา",
    },
    school: {
        school_key: "คำสำคัญของโรงเรียน",
        set_school_key: "ชื่อโรงเรียน",
        area: "ที่ตั้ง",
        course: "ประเภทหลักสูตร",
        set_course: "กรุณาเลือกประเภทหลักสูตร",
        other_course: "รายละเอียดหลักสูตร",
        set_other_course: "กรุณาเลือกหลักสูตร",
        level: "ระดับชั้น",
        set_level: "กรุณาเลือกระดับชั้น",
        lang: "ภาษาในการเรียนการสอน",
        set_lang: "กรุณาเลือกภาษาในการสอน",
        lodge: "พักหอพักหรือไม่",
        lodge_1: "พัก",
        lodge_2: "ไม่พัก",
        money: "ขอบเขตค่าเล่าเรียน (หน่วย：บาท)",
        start_money: "น้อยไปมาก",
        end_money: "มากไปน้อย",
        search: "ค้นหา",
        other_school: "โรงเรียนมากกว่านี้",
        school_list: "รายชื่อโรงเรียนนานาชาติ",
        find_1: "พบโรงเรียนนานาชาติ ทั้งหมดจำนวน ",
        find_2: " แห่ง",
        no_more: "ไม่มีมากกว่านี้แล้ว",
        back: "กลับ",
        school_detail: "รายละเอียดโรงเรียน",
        bookmark: "จัดเก็บ",
        bookmarked: "จัดเก็บแล้ว",
        copy: "คัดลอกลิงค์และแชร์",
        message: "ข้อมูลสำคัญ",
        age: "ช่วงอายุที่รับสมัคร",
        money_detail: "ค่าเล่าเรียน",
        lodge_detail: "พักหอพักหรือไม่",
        time: "เวลาที่จัดตั้ง",
        about: "เกี่ยวกับ ",
        location: "ที่ตั้งโรงเรียน",
        comment: "ความคิดเห็น",
        set_comment: "โพสต์ความคิดเห็นที่เป็นมิตร",
        submit: "โพสต์",
        no_more_commit: "ไม่มีความคิดเห็นมากกว่านี้",
        submit_success: "โพสต์สำเร็จ",
        copy_success: "คัดลอก URL ของหน้านี้สำเร็จแล้ว",
        copy_warning: "คัดลอกล้มเหลว"
    },
    train: {
        course_name: "ชื่อหลักสูตร",
        set_course_name: "กรุณากรอกชื่อหลักสูตร",
        area: "ที่ตั้ง",
        sort: "ประเภทหลักสูตร ",
        set_sort: "กรุณาเลือกประเภทหลักสูตร",
        type: "ประเภทสถาบัน",
        set_type: "กรุณาเลือกสถาบันหรือการศึกษาเอกชน",
        lang: "ภาษาในการสอน",
        set_lang: "กรุณาเลือกภาษาในการสอน",
        is_online: "วิธีการสอน",
        set_is_online: "กรุณาเลือกออนไลน์หรือออฟไลน์",
        class: "ประเภทชั้นเรียน",
        set_class: "กรุณาเลือกประเภทชั้นเรียน",
        pay: "วิธีการชำระเงิน",
        set_pay: "กรุณาเลือกวิธีการชำระเงิน",
        age: "ขอบเขตอายุ",
        start_age: "อายุน้อยที่สุด",
        end_age: "อายุมากที่สุด",
        search: "ค้นหา",
        more: "หลักสูตรเพิ่มเติม",
        list: "รายการหลักสูตรกวดวิชา",
        find_1: "ค้นพบหลักสูตรกวดวิชาทั้งหมด ",
        find_2: " คอร์ส",
        no_more: "ไม่มีมากกว่านี้แล้ว",
        course_info: "ข้อมูลหลักสูตร",
        train_info: "ข้อมูลสถาบัน",
        train_name: "กรุณากรอกชื่อสถาบัน",
        train_location: "ที่ตั้งสถาบัน",
        train_email: "ช่องทางการติดต่อ",
        train_www: "เว็บไซต์ทางการของสถาบัน",
        teacher_info: "ข้อมูลคุณครู",
        teacher_name: "ชื่อคุณครู",
        teacher_detail: "ประวัติคุณครู",
        teacher_type: "ประเภทคุณครู",
        teacher_lang_1: "ครูเจ้าของภาษา",
        teacher_lang_2: "ครูที่ไม่ใช่เจ้าของภาษา",
        teacher_certificate: "ใบรับรองคุณวุฒิ",
        teacher_contact: "ช่องทางการติดต่อ",
        teacher_email: "อีเมล",
    },
    house: {
        location: "ที่ตั้ง",
        type: "ประเภทบ้าน",
        set_type: "กรุณาเลือกประเภทบ้าน",
        school: "ใกล้โรงเรียน",
        set_school: "กรุณาเลือกใกล้โรงเรียน",
        money: "ขอบเขตค่าเช่า (หน่วย：บาท)",
        min_money: "ค่าเช่าน้อยสุด",
        max_money: "ค่าเช่ามากสุด",
        area: "ขนาดพื้นที่บ้าน",
        min_area: "พื้นที่เล็กสุด",
        max_area: "พื้นที่ใหญ่สุด",
        jiaju: "เฟอร์นิเจอร์ตกแต่ง",
        set_jiaju: "กรุณาเลือกเฟอนิเจอร์ตกแต่ง",
        time: "เวลาที่โพสต์",
        search: "คลิกเพื่อค้นหา",
        more: "ข้อมูลมากกว่านี้",
        list: "รายการอสังหาริมทรัพย์ย่านโรงเรียน",
        find_1: "ค้นพบรายการอสังหาริมทรัพย์ย่านโรงเรียนทั้งหมด ",
        find_2: " รายการ",
        no_more: "ไม่มีมากกว่านี้แล้ว",
        mouth: "เดือน",
        detail: "รายละเอียดที่พัก",
        jiaju: "เฟอนิเจอร์",
        type: "ประเภท",
        room: "จำนวนห้องนอน",
    },
    active: {
        label: "ภาพรวม",
        detail: "ประวัติ",
        picture_wall: "รูปภาพ",
        address: "ที่อยู่",
        get_address: "รับตำแหน่งที่ตั้ง",
        type: "ประเภท",
        time: "เวลา",
        start: "เริ่มต้น",
        end: "สิ้นสุด",
        copy_success: "คัดลอกที่อยู่เรียบร้อยแล้ว",
        copy_warning: "คัดลอกล้มเหลว"
    },
    contact: {
        message: "กรุณาฝากข้อความถึงเรา แล้วเราจะติดต่อกลับโดยเร็ว",
        name_1: "นามสกุล",
        name_2: "ชื่อ",
        chose: "ประเทศ",
        mobile: "กรุณากรอกหมายเลขโทรศัพท์",
        email: "อีเมล",
        leave_message: "กรุณาฝากข้อความไว้ที่นี่",
        submit: "ส่ง",
    },
    layout: {
        index: "หน้าแรก",
        account: "ตั้งค่าบัญชี",
        message: "โพสต์ข้อมูล",
        comment: "ซ่อนความคิดเห็น",
    },
    account: {
        title: "ตั้งค่าบัญชี",
        change: "เปลี่ยนโลโก้หรือรูปโปรไฟล์",
        fail: "โหลดภาพไม่สำเร็จ",
        img_title: "คลิกเพื่อเปลี่ยนรูปโปรไฟล์ของผู้ใช้งาน",
        change_info: "แก้ไขข้อมูลส่วนบุคคล",
        train_name: "ชื่อ",
        person_name: "ชื่อ",
        nickname: "ชื่อ",
        teacher_name: "ชื่อ",
        member: "หมายเลขโทรศัพท์",
        change_mobile_1: "แก้ไขหมายเลขโทรศัพท์",
        change_mobile_2: "ผูกกับโทรศัพท์",
        email: "อีเมล",
        change_email_1: "แก้ไขอีเมล",
        change_email_2: "ผูกกับอีเมล",
        name: "ชื่อ-นามสกุล",
        set_name: "กรุณากรอกชื่อ-นามสกุล",
        area: "ที่ตั้ง",
        user: "ประเภทผู้ใช้งาน",
        user_type: "ผู้ใช้ทั่วไป",
        password: "ตั้งค่ารหัสผ่าน",
        save: "บันทึก",
        change_password: "แก้ไขรหัสผ่านส่วนบุคคล",
        old_pwd: "รหัสผ่านเก่า",
        new_pwd: "รหัสผ่านใหม่",
        change_mobile: "แก้ไขหมายเลขโทรศัพท์",
        code: "รับรหัสยืนยัน",
        finish: "เสร็จสิ้น",
        placeholder: "กรุณาเลือก",
        new_mobile: "กรุณากรอกหมายเลขโทรศัพท์",
        set_code: "กรุณากรอกรหัสยืนยัน",
        button_1: "ยกเลิก",
        button_2: "ยืนยัน",
        change_email: "แก้ไขอีเมล",
        new_email: "กรุณากรอกอีเมล",
        type_name_1: "สถาบันฝึกอบรม",
        type_name_2: "นายหน้าอสังหาริมทรัพย์",
        type_name_3: "ผู้ปกครอง",
        type_name_4: "คุณครู",
        message: "ข้อมูลส่วนตัวไม่ได้ถูกเปลี่ยนแปลง",
        up_img: "อัปโหลดรูปภาพโปรไฟล์ในรูปแบบ JPG หรือ PNG เท่านั้น!",
        img_big: "ขนาดของรูปภาพโปรไฟล์ที่อัปโหลดต้องไม่เกิน 2MB!",
        password_title_1: "รหัสผ่านปัจจุบันไม่สามารถเว้นว่างได้",
        password_title_2: "รหัสผ่านใหม่ไม่สามารถเว้นว่างได้",
        password_title_3: "รหัสผ่านใหม่จะต้องไม่เหมือนกับรหัสผ่านปัจจุบัน",
        time_1: "ลองอีกครั้งหลังจาก ",
        time_2: " วินาที",
        mobile_type: "กรุณาตรวจสอบรูปแบบหมายเลขโทรศัพท์ที่กรอก"
    },
    message: {
        title: "โพสต์ข้อความ",
        house: {
            label: "ข้อมูลการเช่าที่พัก",
            id: "รหัสที่พัก",
            type: "ประเภทที่พัก",
            set_type: "กรุณาเลือกประเภทที่พัก",
            title: "หัวข้อ",
            set_title: "กรุณากรอกหัวข้อ",
            area: "ที่ตั้ง",
            set_area: "กรุณาเลือกภูมิภาคของคุณ",
            address: "ที่อยู่โดยละเอียด",
            set_address: "กรุณากรอกรายละเอียดที่อยู่",
            school: "ใกล้โรงเรียน",
            set_school: "กรุณาเลือกหรือใส่โรงเรียน",
            money: "ค่าเช่า (บาท)",
            set_money: "กรุณากรอกค่าเช่า",
            size: "ขนาดพื้นที่ที่พัก",
            set_size: "กรุณากรอกขนาดพื้นที่ที่พัก",
            jiaju: "เฟอนิเจอร์",
            set_jiaju: "กรุณาเลือกเฟอร์นิเจอร์",
            room: "จำนวนห้องนอน",
            set_room: "กรุณาเลือกจำนวนห้องนอน",
            detail: "คำอธิบายที่พัก",
            img: "รูปภาพที่พัก",
            up_img: "อัปโหลดรูปภาพแล้ว",
        },
        course: {
            label: "ข้อมูลหลักสูตร",
            name: "ชื่อหลักสูตร",
            set_name: "กรุณากรอกชื่อหลักสูตร",
            id: "รหัสหลักสูตร",
            logo: "โลโก้หลักสูตร",
            area: "ที่ตั้ง",
            set_area: "กรุณาเลือกภูมิภาคของคุณ",
            type: "ประเภทหลักสูตร",
            set_type: "กรุณาเลือกประเภทหลักสูตร",
            train_type: "ประเภทสถาบัน",
            set_train_type: "กรุณาเลือกสถาบันหรือการศึกษาเอกชน",
            online: "วิธีการสอน",
            set_online: "กรุณาเลือกวิธีการสอน",
            lang: "ภาษาในการสอน",
            set_lang: "กรุณาเลือกภาษาในการสอน",
            class_type: "ประเภทชั้นเรียน",
            set_class_type: "กรุณาเลือกประเภทชั้นเรียน",
            pay: "วิธีการชำระเงิน",
            set_pay: "กรุณาเลือกวิธีการชำระเงิน",
            age: "ขอบเขตอายุ",
            min_age: "อายุน้อยที่สุด",
            max_age: "อายุมากที่สุด",
            info: "ข้อมูลหลักสูตร",
            img: "รูปภาพหลักสูตร",
            up_img: "อัปโหลดรูปภาพแล้ว"
        },
        train: {
            label: "ข้อมูลสถาบัน",
            name: "กรุณากรอกชื่อสถาบัน",
            set_name: "กรุณากรอกชื่อสถาบัน",
            area: "ที่ตั้ง",
            set_area: "กรุณาเลือกประเทศ",
            set_address: "กรุณาป้อนภูมิภาคที่สถาบันตั้งอยู่",
            contact: "ช่องทางการติดต่อ",
            set_contact: "กรุณากรอกอีเมลสถาบัน",
            address: "ที่อยู่",
            address_1: "กรุณากรอกที่อยู่สถาบัน",
            web: "ที่อยู่เว็บไซต์",
            set_web: "กรุณากรอกที่อยู่เว็บไซต์ทางการของสถาบัน",
            btn: "โพสต์"
        },
        teacher: {
            label: "ข้อมูลคุณครู",
            name: "ชื่อ-นามสกุล",
            set_name: "กรุณากรอกชื่อครูผู้สอน",
            detail: "ประวัติ",
            set_detail: "กรุณากรอกประวัติย่อครูผู้สอน",
            lang: "ครูเจ้าของภาษา",
            certificate: "ใบรับรองคุณวุฒิ",
            set_certificate: "กรุณากรอกใบรับรองคุณสมบัติที่เกี่ยวข้อง",
            contact: "ช่องทางการติดต่อ",
            email: "อีเมล",
            set_email: "ลงทะเบียนด้วยอีเมล",
            other: "ช่องทางการติดต่ออื่นๆ",
            set_other: "กรุณากรอกช่องทางการติดต่อ",
            btn: "โพสต์"
        },
        rules: {
            rule_1: "กรุณาเลือกประเภทบ้าน",
            rule_2: "กรุณากรอกชื่อเรื่อง",
            rule_3: "กรุณาเลือกประเทศ",
            rule_4: "กรุณากรอกที่อยู่",
            rule_5: "กรุณากรอกค่าเช่า",
            rule_6: "กรุณากรอกพื้นที่ทรัพย์สิน",
            rule_7: "กรุณาเลือกเฟอนิเจอร์ตกแต่ง",
            rule_8: "กรุณากรอกจำนวนห้องนอน",
            rule_9: "กรุณากรอกรายละเอียดทรัพย์สิน",
            rule_10: "กรุณากรอกชื่อสถาบัน",
            rule_11: "กรุณาอัพโหลดโลโก้สถาบัน",
            rule_12: "กรุณาเลือกสถาบันหรือการศึกษาเอกชน",
            rule_13: "กรุณาเลือกออนไลน์หรือออฟไลน์",
            rule_14: "กรุณาเลือกภาษาในการสอน",
            rule_15: "กรุณาเลือกประเภทหลักสูตร",
            rule_16: "กรุณาเลือกประเภทชั้นเรียน",
            rule_17: "กรุณาเลือกวิธีการชำระเงิน",
            rule_18: "ควรมีอายุขั้นต่ำเป็นอย่างน้อย",
            rule_19: "กรุณากรอกข้อมูลคอร์สเรียน",
            rule_20: "กรุณากรอกข้อมูลสถาบันให้ครบถ้วนก่อน",
            rule_21: "กรุณากรอกข้อมูลครูให้ครบถ้วนก่อน",
        }
    },
    personal: {
        collect: "โพสต์ข้อความและจัดเก็บ",
        my_submit: "โพสต์ของฉัน",
        my_comment: "ความคิดเห็นของฉัน",
        my_collect: "การจัดเก็บของฉัน",
        new_submit: "โพสต์ใหม่ล่าสุด",
        course: "ข้อมูลหลักสูตร",
        time: "เวลาที่โพสต์",
        detail: "ดูรายละเอียด",
        modify: "แก้ไขเนื้อหา",
        del: "ลบบทความนี้",
        no_course_message: "ไม่มีการโพสต์ข้อมูลหลักสูตร",
        house: "ข้อมูลการเช่าที่พัก",
        no_house_message: "ไม่มีการโพสต์ข้อมูลการเช่า",
        mew_comment: "ความคิดเห็นล่าสุด",
        school_comment: "ความคิดเห็นโรงเรียน",
        course_comment: "ความคิดเห็นหลักสูตร",
        no_comment: "ไม่มีการโพสต์ความคิดเห็นใดๆ",
        school_title: "โรงเรียนนานาชาติ",
        course_title: "หลักสูตรกวดวิชา",
        house_title: "อสังหาริมทรัพย์ย่านโรงเรียน",
        no_collect: "ไม่มีรายการซ่อนมากกว่านี้แล้ว",
        del_title: "การดำเนินการดังกล่าวจะลบข้อความนี้อย่างถาวร",
        is_del: "ดำเนินการต่อหรือไม่?",
        title: "คำใบ้",
        yes: "ยืนยัน",
        no: "ยกเลิก",
        del_success: "ลบสำเร็จ!",
        del_warn: "ยกเลิกการลบแล้ว"
    },
    password: {
        account: "ตั้งค่าบัญชี",
        back: "กลับหน้าแรก",
        password: "กู้คืนรหัสผ่าน",
        way: "กรุณาเลือกวิธีการกู้คืน",
        mobile: "หมายเลขโทรศัพท์",
        email: "อีเมล",
        chose: "กรุณาเลือก",
        inner_email: "ลงทะเบียนด้วยอีเมล",
        inner_mobile: "กรุณากรอกหมายเลขโทรศัพท์",
        inner_code: "กรุณากรอกรหัสยืนยัน",
        new_pwd: "กรุณากรอกรหัสผ่านใหม่",
        pwd_input: "ยืนยันรหัสผ่าน",
        last_step: "ก่อนหน้านี้",
        next_step: "ถัดไป",
        get_code: "รับรหัสยืนยัน",
        finish: "เสร็จสิ้น",
        rules: {
            rule_1: "ช่องกรอกเนื้อหาไม่สามารถเว้นว่างได้",
            rule_2: "รหัสผ่านทั้ง2ครั้งต้องเหมือนกัน",
            rule_3: "แก้ไขรหัสผ่านสำเร็จ"
        }
    },
    status: {
        choose: "เลือกสถานะ",
        choose_title: "เลือกสถานะการศึกษานานาชาติวอลสันของคุณ",
        choose_title_2: "คุณต้องเลือกสถานะของคุณเมื่อคุณเข้าสู่ระบบเป็นครั้งแรก",
        chose_btn: "กรุณาเลือกสถานะของคุณ",
        status_1: "สถาบันฝึกอบรม",
        status_2: "นายหน้าอสังหาริมทรัพย์",
        status_3: "ผู้ปกครอง",
        status_4: "คุณครู",
        finish: "เสร็จสิ้น"
    },
    footer: {
        index: "หน้าแรก",
        course: "หลักสูตรกวดวิชา",
        active: "ข้อมูลกิจกรรม",
        contact: "ติดต่อพวกเรา",
        school: "โรงเรียนนานาชาติ",
        school_1: "โรงเรียนนานาชาติในกรุงเทพฯ",
        school_2: "โรงเรียนนานาชาติในภูเก็ต",
        school_3: "โรงเรียนนานาชาติในเชียงใหม่",
        house: "อสังหาริมทรัพย์ย่านโรงเรียน",
        house_1: "คอนโดมิเนียม",
        house_2: "ทาวน์เฮ้าส์",
        house_3: "บ้านเดี่ยว",
        weChat: "WeChat",
        // text: "备案号",
        text_1: "ข้อกำหนดการใช้งาน",
        text_2: "นโยบายความเป็นส่วนตัว"
    }
}