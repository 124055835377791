<template>
  <div class="about">
    <Banner :type="7"></Banner>
    <div class="about_container">
      <div class="about_title">
        <h3>{{ $t("about_us.about_us") }}</h3>
      </div>
      <div class="about_p">
        <div v-html="info"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner.vue";
import { getText } from "@/API/index";
export default {
  components: { Banner },
  data() {
    return {
      righttab: false,
      info: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      getText({ group_id: 2 }).then((res) => {
        // //console.log(res);
        this.info = res.data.data.content;
      });
    },
    showtab() {
      this.righttab = !this.righttab;
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 750px) {
  .about_container {
    width: 100%;
  }
  .about_img {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .about_title {
    margin: 10px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
  }
  .about_nav > ul {
    display: flex;
  }
  .about_nav > ul li {
    line-height: 30px;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
  }
  .phone_hidden {
    visibility: hidden;
  }
  .right_tab {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
    z-index: 99999;
    background-color: rgb(255, 255, 255);
    padding: 10px;
  }
  .right_tab li {
    margin-bottom: 15px;
  }
  .phone_show .right_tab li a {
    font-size: 16px;
    color: rgb(12, 156, 252);
  }
  .right_tab li :nth-last-child(1) {
    margin-bottom: none;
  }
  .menu_i {
    font-size: 28px;
    position: absolute;
    right: 20px;
    top: 15px;
    color: rgb(72, 72, 248);
  }
  .logo {
    width: 30px !important;
    font-size: 28px;
    position: absolute;
    left: 20px;
    top: 15px;
  }
}

@media screen and (min-width: 750px) {
  .about_container {
    width: 80%;
    margin: 0 auto;
  }
  .phone_hidden {
    font-weight: 700;
  }
  .phone_show {
    display: none;
  }
  .about_nav {
    width: 80%;
    display: flex;
    line-height: 60px;
    position: fixed;
    z-index: 9999;
    top: 0;
  }
  .about_nav > ul li {
    margin-left: 15px;
    font-size: 18px;
    margin-right: 15px;
  }
  .about_img {
    width: 80%;
    /* margin-top: 20px; */
    text-align: center;
  }
  .about_title {
    margin: 30px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
}
li {
  list-style: none;
  cursor: pointer;
}
a {
  text-decoration: none;
}
.about_nav {
  display: flex;
  height: 60px;
  justify-content: space-around;
  background-color: rgb(0, 168, 235);
}
.active {
  color: rgb(0, 56, 238) !important;
}
.about_nav > ul {
  display: flex;
}
.about_nav li a {
  color: rgb(255, 255, 255);
}
.logo {
  width: 50px;
}

.about_img img {
  width: 100%;
}
/* .about_title{
    margin: 30px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
} */
.about_title h3 {
  color: #257bcf;
  margin: 8px 0;
}
.about_title > span {
  font-size: 16px;
  color: rgb(134, 133, 133);
  margin: 8px 0;
}
.about_p {
  font-size: 15px;
  color: rgb(141, 140, 140);
  padding: 10px;
  box-sizing: border-box;
}
.about_p > p {
  line-height: 30px;
  margin-bottom: 20px;
}
.about_feature h4 {
  color: rgb(99, 99, 238);
}
.about_feature {
  padding: 10px;
  box-sizing: border-box;
}
.about_feature p {
  line-height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  color: rgb(141, 140, 140);
}
.about_location {
  color: rgb(136, 135, 135);
  font-size: 12px;
  width: 100%;
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgb(240, 240, 240);
}
.location_top {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.location_top_left {
  display: flex;
  flex-direction: column;
}
.location_top_right {
  display: flex;
  flex-direction: column;
}
.location_bottom {
  margin: 30px 5px;
  text-align: center;
}
</style>