import axios from 'axios';
import { Message } from "element-ui"
import Store from "@/store/index"
// 封装服务器地址
// axios.defaults.baseURL = 'http://124.221.161.88:76/';
axios.defaults.baseURL = 'https://am.walson.net/';

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    config.headers.token = Store.state.sdawdascafawdfaw
    let data = { lang: Store.state.lang }
    config.data = { ...config.data, ...data }
    // console.log(config, "11111111111111");
    return config;
}, function (error) {
    return Promise.reject(error);
});


// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let { data } = response
    // //console.log(data.msgcode);
    // 错误代码
    if (data.msgcode < 0) {
        Message({
            type: "error",
            message: data.msgstr
        })
        return new Promise(() => { })
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// 暴露axios
export default axios