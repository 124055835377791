<template>
  <div class="Nav">
    <!-- 轮播图 -->
    <div class="lun" style="width: 100%; margin: 0">
      <el-carousel
        :height="bannerHeight + 'px'"
        style="border-radius: 0 0 10px 10px"
      >
        <el-carousel-item v-for="item in imgList" :key="item.id + 'banner'">
          <img :src="item.banner" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <div class="des-title" id="des">
        <div>{{ $t("index.international_schools") }}</div>
        <div @click="$router.push('/school')" style="cursor: pointer">
          {{ $t("index.more") }}
        </div>
      </div>
      <!-- 学校代表图 -->
      <div class="des school-list" ref="schoolList">
        <div
          class="school-item"
          v-for="(item, index) in schoolList"
          :key="item.id + 'school'"
          @click="openDetail('school', item)"
          @contextmenu="openDetail('school', item)"
          ref="school"
          :style="[
            {
              height:
                index == 0 ? itemHeight * 2 + 10 + 'px' : itemHeight + 'px',
            },
          ]"
        >
          <a
            href="https://www.walson.net#/school"
            style="width: 100%; height: 100%"
          >
            <img
              ref="schoolImgs"
              src="../assets/loading.gif"
              style="width: 20px; height: 20px"
              :data-url="item.show_image"
          /></a>
        </div>
      </div>

      <!-- 培训辅导板块 -->
      <!-- <div class="train" id="train1">
        <div class="train-title">
          <span style="color: #000">培训机构</span>
          <span @click="to_search" style="cursor: pointer; color: #000"
            >更多</span
          >
        </div>
        <div class="des school-list" ref="trainList_1">
          <div
            class="school-item"
            v-for="(item, index) in trainList_1"
            :key="item.id + 'school'"
            @click="$router.push('/school')"
            ref="school"
            :style="[
              {
                height:
                  index == 0 ? itemHeight * 2 + 10 + 'px' : itemHeight + 'px',
              },
            ]"
          >
            <img
              ref="schoolImgs"
              src="../assets/loading.gif"
              style="width: 20px; height: 20px"
              :data-url="item.show_image"
            />
          </div>
        </div>
      </div> -->
      <div class="train2" id="train2">
        <div class="train-title2">
          <span>{{ $t("index.tutoring_courses") }}</span>
          <span @click="to_search" style="cursor: pointer">
            {{ $t("index.more") }}
          </span>
        </div>
        <!-- 学校代表图 -->
        <div class="des school-list" ref="trainList_2">
          <div
            class="school-item"
            v-for="(item, index) in trainList_2"
            :key="item.id + 'school'"
            @click="openDetail('train', item)"
            @contextmenu="openDetail('train', item)"
            ref="school"
            :style="[
              {
                height:
                  index == 0 ? itemHeight * 2 + 10 + 'px' : itemHeight + 'px',
              },
            ]"
          >
            <a
              href="https://www.walson.net/#/searchorgan"
              style="width: 100%; height: 100%"
            >
              <img
                ref="schoolImgs"
                src="../assets/loading.gif"
                style="width: 20px; height: 20px"
                :data-url="item.show_image"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- 房屋中介代表图 -->
      <div class="house" id="house">
        <div class="house-title">
          <span class="houserent">
            {{ $t("index.school_district_housing") }}
          </span>
          <span style="cursor: pointer" @click="to_housedetail">
            {{ $t("index.more") }}
          </span>
        </div>
        <div class="des school-list" ref="houseList">
          <div
            class="school-item"
            v-for="(item, index) in houseList"
            :key="item.id + 'school'"
            @click="openDetail('house', item)"
            @contextmenu="openDetail('house', item)"
            ref="school"
            :style="[
              {
                height: index == 0 ? firstHeight + 'px' : itemHeight + 'px',
              },
            ]"
          >
            <a
              href="https://www.walson.net/#/housedetail"
              style="width: 100%; height: 100%"
            >
              <img
                ref="schoolImgs"
                src="../assets/loading.gif"
                style="width: 20px; height: 20px"
                :data-url="item.show_image"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- 活动代表图 -->
      <div class="house" id="active">
        <div class="house-title">
          <span class="houserent">
            {{ $t("index.event_information") }}
          </span>
          <span style="cursor: pointer" @click="$router.push('/activity')">
            {{ $t("index.more") }}
          </span>
        </div>
        <div class="des school-list" ref="activeList">
          <div
            class="school-item"
            v-for="(item, index) in activeList"
            :key="item.id + 'school'"
            @click="openDetail('active', item)"
            @contextmenu="openDetail('active', item)"
            ref="school"
            :style="[
              {
                height: index == 0 ? firstHeight + 'px' : itemHeight + 'px',
              },
            ]"
          >
            <a
              :href="
                item.url == 'events'
                  ? 'https://www.walson.net/#/activity'
                  : item.url == 'summer camp'
                  ? 'https://www.walson.net/#/activity?id=1'
                  : item.url == 'education fairs'
                  ? 'https://www.walson.net/#/activity?id=2'
                  : item.url == 'education salon'
                  ? 'https://www.walson.net/#/activity?id=3'
                  : 'https://www.walson.net/#/activity?id=4'
              "
              style="width: 100%; height: 100%"
            >
              <img
                ref="schoolImgs"
                src="../assets/loading.gif"
                style="width: 20px; height: 20px"
                :data-url="item.show_image"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- 左侧fixed侧边栏 -->
      <ul
        class="nav-left"
        ref="nav_left"
        :style="{ left: fixedWidth - 20 + 'px' }"
        v-if="isleft_fixed"
      >
        <li>
          <a href="#des">{{ $t("index.nav_1") }}</a>
        </li>
        <!-- <li><a href="#train1">培训机构 </a></li> -->
        <li>
          <a href="#train2">{{ $t("index.nav_2") }}</a>
        </li>
        <li>
          <a href="#house">{{ $t("index.nav_3") }}</a>
        </li>
        <li>
          <a href="#active">{{ $t("index.nav_4") }}</a>
        </li>
        <li @click="back">
          <a>{{ $t("index.nav_5") }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getIndexInfo, getBanner } from "@/API/index";
export default {
  components: {},
  data() {
    return {
      fixedWidth:
        (document.documentElement.clientWidth -
          document.documentElement.clientWidth * 0.8) /
          2 -
        50, //实时屏幕宽度
      // 左侧侧边栏
      isleft_fixed: false,
      rightTab: false,
      // 轮播图s
      imgList: [],
      houseList: [],
      schoolList: [],
      trainList_1: [],
      trainList_2: [],
      activeList: [],
      bannerHeight: 815,
      itemHeight: 0,
      firstHeight: 0,
    };
  },

  mounted() {
    this.getInfo();
    window.addEventListener("scroll", this.handle);
    window.addEventListener("scroll", this.lazyLoad);
    // window.addEventListener("resize", this.getNavLeft);
    this.bannerHeight = (document.documentElement.clientWidth / 384) * 163;
    window.addEventListener("resize", this.getNavLeft);
  },
  updated() {
    this.getFlex();
    this.lazyLoad();
  },
  methods: {
    lazyLoad() {
      // console.log(this.$refs.schoolImgs);
      if (this.$refs.schoolImgs != undefined) {
        this.$refs.schoolImgs.forEach((item, index) => {
          // console.log(item.dataset.url);
          // if (index == 0) {
          //   console.log(
          //     document.documentElement.scrollTop,
          //     window.innerHeight,
          //     item.offsetParent.offsetTop
          //   );
          // }
          setTimeout(() => {
            if (
              document.documentElement.scrollTop + window.innerHeight >
              item.offsetParent.offsetTop
            ) {
              item.src = item.dataset.url;
              item.style.width = "100%";
              item.style.height = "100%";
              item.style.cursor = "pointer";
            }
          }, 50);
        });
      }
    },

    //获取首页数据
    async getInfo() {
      await getIndexInfo().then((res) => {
        // //console.log(res.data.data);
        this.imgList = res.data.data.banner;
        this.houseList = res.data.data.house_list;
        this.schoolList = res.data.data.school_list;
        this.trainList_1 = res.data.data.train_list_1;
        this.trainList_2 = res.data.data.train_list_2;
        this.activeList = res.data.data.active_list;
      });
    },
    handle() {
      if (document.documentElement.scrollTop > this.bannerHeight) {
        this.isleft_fixed = true;
      }
      if (document.documentElement.scrollTop < this.bannerHeight) {
        this.isleft_fixed = false;
      }
    },
    back() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    showTab() {
      this.rightTab = !this.rightTab;
    },
    // 关于页面
    to_about() {
      this.$router.push("/about");
    },
    // 搜索页面
    to_school() {
      this.$router.push("/school");
    },
    to_relation() {
      this.$router.push("/relation");
    },
    to_activity() {
      this.$router.push("/activity");
    },
    to_housedetail() {
      this.$router.push("/housedetail");
    },
    to_mine() {
      this.$router.push("/mine");
    },
    to_search() {
      this.$router.push("/searchorgan");
    },
    login_child() {
      this.rightTab = false;
      // this.$refs.login.showlogin();
    },
    getNavLeft() {
      this.fixedWidth = (document.documentElement.clientWidth - 1300) / 2 - 40;
      this.bannerHeight = (document.documentElement.clientWidth / 384) * 163;
      setTimeout(() => {
        if (this.$refs.schoolList) {
          this.$refs.schoolList.style.gridTemplateColumns = `${
            (this.firstHeight / 4) * 3
          }px 1fr 1fr`;
        }
        if (this.$refs.trainList_1) {
          this.$refs.trainList_1.style.gridTemplateColumns = `${
            (this.firstHeight / 4) * 3
          }px 1fr 1fr`;
        }
        if (this.$refs.trainList_2) {
          this.$refs.trainList_2.style.gridTemplateColumns = `${
            (this.firstHeight / 4) * 3
          }px 1fr 1fr`;
        }
        if (this.$refs.houseList) {
          this.$refs.houseList.style.gridTemplateColumns = `${
            (this.firstHeight / 4) * 3
          }px 1fr 1fr`;
        }
        if (this.$refs.activeList) {
          this.$refs.activeList.style.gridTemplateColumns = `${
            (this.firstHeight / 4) * 3
          }px 1fr 1fr`;
        }
      }, 1000);
    },
    getFlex() {
      setTimeout(() => {
        if (this.$refs.school[1]) {
          this.itemHeight = (this.$refs.school[1].offsetWidth / 16) * 9;
          this.firstHeight = this.itemHeight * 2 + 10;
        }
      }, 1000);
    },
    openDetail(tag, item) {
      let sort = item.url;
      // console.log(tag, sort);
      switch (tag) {
        case "school":
          // console.log(sort);
          switch (sort) {
            // 全部学校
            case "international schools":
              break;
            // 美制
            case "american curriculum":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lesson_id: 2,
              });
              break;
            // 英制
            case "british curriculum":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lesson_id: 1,
              });
              break;
            // IB制
            case "IB curriculum":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lesson_id: 3,
              });
              break;
            // 其他课程
            case "other curricula":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lesson_id: 4,
              });
              break;
          }
          break;
        case "train":
          console.log(sort);
          switch (sort) {
            // 线上
            case "online tutoring":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                onlinetype: 1,
              });
              break;
            // 语言辅导
            case "language tutoring":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lessontype: 2,
              });
              break;
            // 面试辅导
            case "interview skills":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lessontype: 1,
              });
              break;
            // 学术课程
            case "academic tutoring":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lessontype: 3,
              });
              break;
            // 其他课程
            case "other tutoring courses":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                lessontype: 4,
              });
              break;
          }
          break;
        case "house":
          console.log(sort);
          switch (sort) {
            // 学区房产
            case "school district housing":
              break;
            // 公寓
            case "condominium":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                type: 1,
              });
              break;
            // 联排房屋
            case "townhouse":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                type: 2,
              });
              break;
            // 独栋别墅
            case "single house":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                type: 3,
              });
              break;
            // 在曼谷
            case "outside bangkok":
              this.$store.commit("SUBMIT_SEARCH_INFO", {
                type: 4,
              });
              break;
          }
          break;
        case "active":
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/* 750> max*/
@media screen and (min-width: 750px) {
  .container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    font-size: 16px;
  }
  .phone_show {
    display: none;
  }
  .nav-left {
    position: fixed;
    // display: none;
    top: 27%;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
  }
  .nav-left li:last-child {
    margin-bottom: 0;
  }
  // .nav {
  //   width: 80%;
  //   position: fixed;
  //   // z-index: 9999;
  //   top: 0;
  //   line-height: 60px;
  // }
  // .lun {
  //   margin-top: 80px;
  // }
  .copybottom {
    width: 100%;
  }
  .nav-right ul li {
    margin-left: 15px;
    font-size: 18px;
    margin-right: 15px;
  }
}
.logo {
  width: 50px;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}
.nav {
  display: flex;
  height: 60px;
  // position: fixed;
  // top: 0;
  // z-index: 999;
  justify-content: space-around;
  background-color: rgb(0, 168, 235);
}
.active {
  color: rgb(0, 56, 238) !important;
}
.nav-right ul {
  display: flex;
}
.nav-right ul li {
  a {
    color: rgb(255, 255, 255);
    font-weight: 700;
    cursor: pointer;
  }
  // margin-left: 10px;
  // font-size: 1rem;
}
.des-title {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: 700;
  // font-size: 18px;
}
.des {
  width: 100%;
  display: grid;
  /* 设置列宽 */
  // grid-template-columns: repeat(3, auto);
  grid-template-columns: 32.7% 32.7% 32.7%;
  grid-gap: 1%;
  row-gap: 10px;
  // flex-wrap: wrap;
  // justify-content: space-between;

  .school-item:first-child {
    // grid-column-start: 1;
    // grid-column-end: 2;
    grid-row: 1 / span 2;
    height: 510px;
  }
  .school-item {
    width: 100%;
    position: relative;
    // margin-bottom: 10px;
    height: 250px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
    .school-title {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 2em;
      color: #fafafa;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .school-item:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.nav-left li {
  width: 55px;
  height: 60px;
  padding: 0 10px;
  font-size: 12px;
  background-color: #32485c;
  opacity: 0.9;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  cursor: pointer;
}
.nav-left li a {
  color: #fff;
}
.nav-left li:hover {
  background-color: #32485c;
}
/* 轮播图样式 */
.SwiperBox {
  position: relative;
  margin-top: 30px;
  border-radius: 4px;
  width: 100%;
  height: 350px;
  // border: 1px solid #ccc;
  box-sizing: border-box;
  cursor: pointer;
}
/* 图片默认样式 */
.imgCss {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 350px;
  border-radius: 4px;
  opacity: 0;
  transition: 1s; /* 淡入淡出过渡时间 */
}
/* 图片选中样式(继承上方默认样式) */
.ShowCss {
  opacity: 1;
}
/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 109, 109, 0.445);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}
.leftBtn {
  left: 10px;
}
.rightBtn {
  right: 10px;
}
/* 下方指示器盒子 */
.instBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  display: flex;
}
/* 小圆点 */
.inst {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.inst:last-child {
  margin-right: 0px;
}
.instActv {
  border: 1px solid #fafafa;
  background: #aca7a7;
}
// 培训1样式
.train {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-color: rgb(224, 221, 221);
  color: #fff;
}
// .train li:hover {
//   background-color: #66b1ff;
// }
// .train2 li:hover {
//   background-color: #66b1ff;
// }
.train-title {
  padding: 10px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
.train-list {
  display: flex;
  justify-content: space-around;
  text-align: center;
  // line-height: 50px;
  flex-wrap: wrap;
}
.train-list li {
  // margin-bottom: 20px;
  border-radius: 12px;
  height: 250px;
  overflow: hidden;
  // background-color: #409eff;
}
.train2 {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-color: rgb(224, 221, 221);
}
.train-title2 {
  font-weight: 700;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.train-list2 {
  display: flex;
  justify-content: space-around;
  text-align: center;
  // line-height: 50px;
  flex-wrap: wrap;
}
.train-list2 li {
  // width: 15%;
  height: 250px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  // background-color: rgb(150, 213, 233);
}
.house {
  margin-top: 30px;
  width: 100%;
  // background: #ccc;
}
.houserent {
  cursor: pointer;
}
.house-list {
  overflow: hidden;
}
.house-list img {
  width: 100%;
  height: 100%;
}
.copyright {
  margin-top: 30px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  background-color: #f0f0f0;
}
.copyright-list {
  width: 100%;
  display: flex;

  justify-content: center;
  text-align: center;
}
.copyright ul {
  flex: 25%;
  padding: 1%;
  border-bottom: 1px solid #ccc;
  h4 {
    margin-bottom: 15px;
    // margin-top: 15px;
    font-size: 15px;
    color: rgb(66, 65, 65);
  }
}
.copyright ul li {
  margin-bottom: 5px;
  padding-bottom: 2px;
  font-size: 14px;
  color: #777;
}
.house-title {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.copybottom_p {
  color: #999;
  font-size: 12px;
  text-align: center;
}
.banner {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 550px;
  background-color: rgb(124, 210, 250);
  padding: 20px;
  border-radius: 30px;
}
.banner > div {
  border-radius: 30px;
  text-align: center;
  width: 650px;
  height: 150px;
  line-height: 150px;
  margin-bottom: 20px;
  background-color: rgb(92, 248, 248);
}
.setting {
  border: 1px solid #ccc;
  height: 30px;
  text-align: center;
}
.house-item {
  border-radius: 4px;
  height: 600px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    // position: relative;
    margin: 0 auto;
    font-size: 16px;
  }
  .nav-left {
    width: 50px;
    position: fixed;
    display: none !important;
    top: 410px;
    left: 0px;
  }
  .nav-right {
    display: none;
  }
  .nav {
    width: 100%;
    position: fixed;
    // z-index: 9999;
    top: 0;
    line-height: 30px;
  }
  // .lun {
  //   // margin-top: 59px;
  // }
  .SwiperBox {
    width: 96%;
    margin-left: 2%;
    height: 200px !important;
    border-radius: 4px;
    overflow: hidden;
  }
  .copybottom {
    width: 200%;
    margin-top: 20px;
    transform: scale(0.5);
    transform-origin: 0 0;
  }
  .nav-right ul li {
    line-height: 30px;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
  }
  //
  .housedetail .phone_hidden {
    display: none;
  }
  .right_tab {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
    // z-index: 99999;
    background-color: rgb(255, 255, 255);
    padding: 10px;
  }
  .right_tab li {
    margin-bottom: 15px;
  }
  .phone_show .right_tab li a {
    font-size: 16px;
    color: rgb(12, 156, 252);
  }
  .right_tab li :nth-last-child(1) {
    margin-bottom: none;
  }
  .menu_i {
    font-size: 28px;
    position: absolute;
    right: 20px;
    top: 15px;
    color: rgb(72, 72, 248);
  }
  .logo {
    width: 30px !important;
    font-size: 28px;
    position: absolute;
    left: 20px;
    top: 15px;
  }
  .copyright-list h4 {
    font-size: 13px !important;
  }
  .copyright-list li {
    font-size: 12px !important;
  }
  .des .school-item {
    width: 96%;
    margin-left: 2%;
  }
  // .house-item {
  //   width: 96%;
  //   margin-left: 2%;
  // }
  .des {
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-gap: 0;
    /* 设置列宽 */
    // grid-template-columns: repeat(3, auto);
    grid-template-columns: 100% !important;
    row-gap: 10px;
    // flex-wrap: wrap;
    // justify-content: space-between;
  }
}
</style>