import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/icon/iconfont.css'
import i18n from "@/utils/i18n"
import VueClipBoard from 'vue-clipboard2'
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.config.productionTip = false
// 重置样式
import '@/assets/css/normalize.css'
import '@/assets/css/reset.css'
Vue.use(VueClipBoard)
Vue.use(ElementUI)
Vue.use(SlideVerify);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
