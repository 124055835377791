<template>
  <div class="relation">
    <Banner :type="6"></Banner>
    <div class="relation_container">
      <div class="relation_container_header">
        {{ this.$store.state.info.site_title }}-{{
          $i18n.locale == "zh"
            ? "联系我们"
            : $i18n.locale == "thai"
            ? "ติดต่อพวกเรา"
            : "Contact Us"
        }}
      </div>
      <div class="relation_container_content">
        <div class="address_map">
          <google-map
            ref="google-map"
            :lat="Number($store.state.info.lat)"
            :lng="Number($store.state.info.lng)"
            :address="$store.state.info.address"
            type="contact"
          />
        </div>
        <div class="contact_us_form">
          <div>{{ $t("contact.message") }}</div>
          <div class="form">
            <div class="form_name form_item">
              <el-input
                size="medium"
                v-model="form.name_2"
                :placeholder="$t('contact.name_2')"
                :validate-event="false"
              ></el-input>
              <el-input
                size="medium"
                v-model="form.name"
                :placeholder="$t('contact.name_1')"
                :validate-event="false"
              ></el-input>
            </div>
            <div class="form_mobile form_item">
              <el-select
                size="medium"
                v-model="form.mobile_code"
                filterable
                :placeholder="$t('contact.chose')"
              >
                <el-option
                  v-for="(item, index) in code_list"
                  :key="item.cn + index"
                  :label="
                    $i18n.locale == 'zh'
                      ? item.code + ' ' + '(' + item.cn + ')'
                      : item.code + ' ' + '(' + item.en + ')'
                  "
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-input
                size="medium"
                v-model="form.phone"
                :placeholder="$t('contact.mobile')"
                :validate-event="false"
              ></el-input>
            </div>
            <div class="form_email form_item">
              <el-input
                v-model="form.email"
                :placeholder="$t('contact.email')"
              ></el-input>
            </div>
            <div class="form_item">
              <el-input
                type="textarea"
                :rows="2"
                :autosize="{ minRows: 3, maxRows: 8 }"
                :placeholder="$t('contact.leave_message')"
                v-model="form.content"
              >
              </el-input>
            </div>
          </div>
          <div class="form_line">
            <!-- <el-input v-model="form.line" placeholder="line ID"></el-input> -->
            <!-- <el-checkbox v-model="checked" style="margin-top: 20px">
              同意条款
            </el-checkbox> -->
          </div>
          <div class="form_submit">
            <div class="submit_form">
              <el-button type="primary" plain @click="submit">{{
                $t("contact.submit")
              }}</el-button>
            </div>
            <div class="submit_call">
              <div>Email: {{ this.$store.state.info.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMap from "@/components/googleMap.vue";
import Banner from "@/components/banner.vue";
import { codeList } from "@/assets/js/country";
import { contactUs } from "@/API/index";
export default {
  components: { Banner, GoogleMap },
  data() {
    return {
      form: {},
      checked: false,
      lat: 20,
      lng: 0,
      code_list: [],
    };
  },
  mounted() {
    this.getinfo();
    this.code_list = codeList.list;
  },
  methods: {
    getinfo() {
      //console.log(this.$store.state.info);
      this.lat = Number(this.$store.state.info.lat);
      this.lng = Number(this.$store.state.info.lng);
    },
    async submit() {
      if (this.form.phone && this.form.mobile_code) {
        this.form.phone = (this.form.mobile_code + this.form.phone).substr(1);
        delete this.form.mobile_code;
      }
      //console.log(this.form);
      await contactUs(this.form).then((res) => {
        this.$message.success(res.data.msgstr);
      });
      this.form = {};
    },
    // phone() {
    //   window.location.href = "tel:" + this.$store.state.info.phone;
    // },
  },
};
</script>
<style scoped lang="less">
.relation {
  width: 100%;
  height: 100%;
  .relation_container {
    width: 80%;
    margin: 20px auto;
    .relation_container_header {
      font-size: 28px;
      font-weight: 700;
      margin-block: 40px;
    }
    .relation_container_content {
      display: flex;
      height: 500px;
      .address_map {
        flex: 1;
        height: 100%;
      }
      .contact_us_form {
        flex: 1;
        height: 100%;
        padding: 0 30px;
        .form {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          // justify-content: space-evenly;
          .form_item {
            margin-bottom: 20px;
          }
          .form_name {
            display: flex;
            .el-input:first-child {
              margin-right: 20px;
            }
          }
          .form_mobile {
            display: flex;
            .el-select {
              margin-right: 10px;
            }
          }
        }

        .form_submit {
          display: flex;
          .submit_form {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              width: 50%;
            }
          }
          .submit_call {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .relation {
    width: 100%;
    height: 800px;
    .relation_container {
      .relation_container_content {
        display: block;
        .address_map {
          width: 100%;
          height: 300px;
        }
        .contact_us_form {
          width: 100%;
          height: 300px;
          .form_submit {
            display: block;
            .submit_form {
              widows: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 20px;
              button {
                width: 50%;
              }
            }
            .submit_call {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>