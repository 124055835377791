<template>
  <div class="map">
    <div id="mapBox" style="width: 100%; height: 100%"></div>
    <div class="innerWindow">
      <div class="detail">
        <div class="left">
          <h3>{{ name }}</h3>
          <div class="address">{{ newAddress }}</div>
        </div>
        <div class="direction" @click="direction">
          <i class="el-icon-position"></i>
          <div>Directions</div>
        </div>
      </div>
      <div class="reviews_rating" v-if="type != 'contact'">
        <div class="rating">
          <span>{{ rating }}</span>
          <el-rate
            v-model="rating"
            disabled
            text-color="#ff9900"
            score-template="{value}"
          >
          </el-rate>
        </div>
        <div class="reviews" @click="submitReviews">
          {{ reviews + " reviews" }}
        </div>
      </div>
      <span class="larger" @click="openMap">View larger map</span>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"; //引入
const loader = new Loader({
  apiKey: "AIzaSyDb95D81xdvE6J210i32w0ZAI9ZQLBvQ80", //之前的key
  version: "weekly", //版本
  libraries: ["places"],
});
export default {
  props: ["lat", "lng", "address", "type"],
  watch: {
    lat(newVal, oldVal) {
      // //console.log(this.lat, this.lng);
      // console.log(newVal, "<<<<<<<<<<<<<<newVal");
      this.initMap();
    },
    // address(newVal, oldVal) {
    //   // //console.log(this.address);
    //   console.log(newVal, "<<<<<<<<<<<<<<<<<<<<地址");
    //   this.initMap();
    // },
  },
  data() {
    return {
      newLat: "",
      newLng: "",
      newAddress: "",
      placeId: "",
      rating: 0,
      reviews: 0,
      name: "",
      url: "",
    };
  },
  mounted() {
    // //console.log(this.address);
    if (this.lat && this.lng) {
      this.initMap();
    }
  },
  methods: {
    openMap() {
      // @${this.lat},${this.lng}
      // https://maps.google.com/maps?ll=13.75434,100.67177&z=17&t=m&hl=en&gl=TH&mapclient=embed&cid=40929573297445599
      //https://search.google.com/local/reviews?placeid=ChIJu-PEGHNhHTER39oz5TppkQA&q=Brighton+College+Bangkok&hl=en&gl=TH
      window.open(this.url + `&z=14&t=m&mapclient=embed`);
    },
    submitReviews() {
      window.open(this.url + `&z=14&t=m&mapclient=embed`);
    },
    direction() {
      let address = this.newAddress.replace("/", "%2F");
      window.open(
        `https://maps.google.com/maps/dir//${address}/@${this.newLat},${this.newLng},14z`
      );
    },
    initMap() {
      let vue_this = this;
      let center = { lat: Number(vue_this.lat), lng: Number(vue_this.lng) };
      // console.log(center, "<<<<<<<<<<<<地图中心");
      const mapOptions = {
        center: center,
        zoom: 17,
        disableDefaultUI: true,
      };
      let getPlaceId = {
        query: vue_this.address,
        fields: ["place_id"],
        locationBias: center,
      };
      loader
        .load()
        .then((google) => {
          const map = new google.maps.Map(
            document.getElementById("mapBox"),
            mapOptions
          );
          let service = new google.maps.places.PlacesService(map);

          service.findPlaceFromQuery(getPlaceId, function (res, status) {
            // console.log(res);
            // console.log(status);
            if (status == "OK") {
              vue_this.placeId = res[0].place_id;
              let placeDetail = {
                placeId: vue_this.placeId,
                fields: [
                  "geometry",
                  "formatted_address",
                  "rating",
                  "user_ratings_total",
                  "name",
                  "url",
                ],
              };
              service.getDetails(placeDetail, callback);
              function callback(place, status) {
                // console.log(place, status);
                if (status == "OK") {
                  vue_this.newLat = place.geometry.location.lat();
                  vue_this.newLng = place.geometry.location.lng();
                  vue_this.newAddress = place.formatted_address;
                  vue_this.rating = place.rating;
                  vue_this.reviews = place.user_ratings_total;
                  vue_this.name = place.name;
                  vue_this.url = place.url;
                  map.setCenter(place.geometry.location);
                  new google.maps.Marker({
                    position: {
                      lat: vue_this.newLat,
                      lng: vue_this.newLng,
                    },
                    map,
                  });
                } else {
                  vue_this.$message.error("An error occurred");
                }
              }
            } else {
              vue_this.$message.error("An error occurred");
            }
          });
        })
        .catch((e) => {
          // do something
          //console.log(e);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.map {
  position: relative;
  width: 100%;
  height: 100%;
  .innerWindow {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px;
    background: #fff;
    z-index: 1000002;
    border-radius: 8px;
    color: #5b5b5b;
    box-sizing: border-box;
    .detail {
      display: flex;
      align-items: center;
      .left {
        width: 200px;
        h3 {
          font-weight: 500;
          font-size: 14px;
          position: relative;
        }
        .address {
          font-size: 12px;
          margin: 5px 0 3px;
        }
      }
      .direction {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        i {
          font-size: 30px;
          color: #1a73e8;
        }
        div {
          color: #1a73e8;
          font-size: 12px;
          border-bottom: 1px solid transparent;
          font-weight: 600;
          margin-top: 3px;
        }
        &.direction:hover div {
          border-bottom: 1px solid #1a73e8;
        }
      }
    }

    .reviews_rating {
      display: flex;
      align-items: center;
      .rating {
        display: flex;
        align-items: center;
        color: #5b5b5b;
        font-weight: 500;
        font-size: 14px;
        /deep/.el-rate {
          height: auto;
          margin-left: 2px;
        }
        /deep/.el-rate__icon {
          margin-right: 0;
          font-size: 16px;
        }
      }
      .reviews {
        margin-left: 8px;
        color: #1a73e8;
        font-size: 12px;
        border-bottom: 1px solid transparent;
        font-weight: 600;
      }
      .reviews:hover {
        border-bottom: 1px solid #1a73e8;
        cursor: pointer;
      }
    }
    .larger {
      color: #1a73e8;
      font-size: 12px;
      border-bottom: 1px solid transparent;
      font-weight: 600;
      margin: 10px 0 0;
      display: inline-block;
    }
    .larger:hover {
      border-bottom: 1px solid #1a73e8;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 750px) {
  .address {
    display: none !important;
  }
  .reviews_rating {
    display: none !important;
  }
  .direction {
    display: none !important;
  }
}
</style>


